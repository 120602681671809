import React, { useState } from 'react'
import cn from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import { configBanks, configDevelopers, insuranceCompanies } from './config'
import maksInfo from './mask.pdf'
import * as styles from './partnerBank.module.less'

const ArrowLeft = (props) => {
  const disabled = props.disabled ? styles.arrowDisabled : ''
  return (
    <div
      onClick={props.onClick}
      className={cn(styles.arrow, styles.arrowLeft, disabled)}
    >
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.792 30.821L23.783 33.83L6.95299 17L23.783 0.170044L26.792 3.17904L12.971 17L26.792 30.821H26.792Z"
          fill="#9BA2AB"
        />
      </svg>
    </div>
  )
}

const ArrowRight = (props) => {
  const disabled = props.disabled ? styles.arrowDisabled : ''
  return (
    <div
      onClick={props.onClick}
      className={cn(styles.arrow, styles.arrowRight, disabled)}
    >
      <svg
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.208008 30.821L3.21701 33.83L20.047 17L3.21701 0.170044L0.208008 3.17904L14.029 17L0.208008 30.821H0.208008Z"
          fill="#9BA2AB"
        />
      </svg>
    </div>
  )
}

export default () => {
  const [currentSlideBanks, setCurrentSlideBanks] = useState(0)
  const [currentSlideDevelopers, setCurrentSlideDevelopers] = useState(0)
  const [currentSlideCompanies, setCurrentSlideCompanies] = useState(0)

  const [loaded, setLoaded] = useState(false)

  const settings = {
    mode: 'free-snap',
    slides: {
      perView: 2.5,
      spacing: 10,
    },
    breakpoints: {
      '(min-width: 450px)': {
        slides: {
          perView: 3.5,
          spacing: 15,
        },
      },
      '(min-width: 600px)': {
        slides: {
          perView: 4.5,
          spacing: 15,
        },
      },
      '(min-width: 768px)': {
        slides: {
          perView: 3.5,
          spacing: 15,
        },
      },
      '(min-width: 1280px)': {
        slides: {
          perView: 4.5,
          spacing: 20,
        },
      },
      '(min-width: 1400px)': {
        slides: {
          perView: 'auto',
          spacing: 20,
        },
      },
    },
  }

  const settingsBanks = {
    ...settings,
    slideChanged(s) {
      setCurrentSlideBanks(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  }

  const settingsDevelopers = {
    ...settings,
    slideChanged(s) {
      setCurrentSlideDevelopers(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  }

  const settingsCompanies = {
    ...settings,
    slideChanged(s) {
      setCurrentSlideCompanies(s.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
  }

  const [sliderBanks, sliderBanksRef] = useKeenSlider(settingsBanks)
  const [sliderDevelopers, sliderDevelopersRef] =
    useKeenSlider(settingsDevelopers)
  const [sliderInsuranceCompanies, sliderInsuranceCompaniesRef] =
    useKeenSlider(settingsCompanies)

  const onClickNext = (ref) => (e) => {
    e.stopPropagation() || ref.current?.next()
  }

  const onClickPrev = (ref) => (e) => {
    e.stopPropagation() || ref.current?.prev()
  }

  const onClickNextDisabled = (currentSlide, sliderRef) => {
    return (
      currentSlide === sliderRef.current?.track.details.maxIdx ||
      sliderRef.current?.track.details.slides.length === 0
    )
  }

  const onClickPrevDisabled = (currentSlide) => {
    return currentSlide === 0
  }

  return (
    <section
      id={'partnerBanks'}
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr'
      )}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Наши партнёры</h2>
        <h3 className={styles.subTitle}>Ведущие ипотечные банки страны</h3>
        <div className={styles.wrapperSlider}>
          <div
            ref={sliderBanks}
            className={cn(
              'sliderPartners',
              'keen-slider',
              styles.sliderPartners
            )}
          >
            {configBanks.map((bank) => (
              <div
                key={bank.title}
                className={cn(styles.slide, 'keen-slider__slide partnersSlide')}
              >
                <div className={styles.imgWrapper}>
                  <img
                    src={bank.logo.big}
                    alt={`${bank.title}`}
                    loading="lazy"
                  />
                </div>
              </div>
            ))}
          </div>
          {loaded && sliderBanksRef.current && (
            <>
              <ArrowLeft
                onClick={onClickPrev(sliderBanksRef)}
                disabled={onClickPrevDisabled(currentSlideBanks)}
              />
              <ArrowRight
                onClick={onClickNext(sliderBanksRef)}
                disabled={onClickNextDisabled(
                  currentSlideBanks,
                  sliderBanksRef
                )}
              />
            </>
          )}
        </div>
        <h3 className={styles.subTitle}>Надёжные застройщики</h3>
        <div className={styles.wrapperSlider}>
          <div
            ref={sliderDevelopers}
            className={cn(
              'sliderPartners',
              'keen-slider',
              styles.sliderPartners
            )}
          >
            {configDevelopers.map((developer) => (
              <div
                key={developer.title}
                className={cn(styles.slide, 'keen-slider__slide partnersSlide')}
              >
                <div className={styles.imgWrapper}>
                  <img
                    src={developer.logo.big}
                    alt={`${developer.title}`}
                    loading="lazy"
                  />
                </div>
              </div>
            ))}
          </div>
          {loaded && sliderDevelopersRef && (
            <>
              <ArrowLeft
                onClick={onClickPrev(sliderDevelopersRef)}
                disabled={onClickPrevDisabled(currentSlideDevelopers)}
              />
              <ArrowRight
                onClick={onClickNext(sliderDevelopersRef)}
                disabled={onClickNextDisabled(
                  currentSlideDevelopers,
                  sliderDevelopersRef
                )}
              />
            </>
          )}
        </div>
        <h3 className={styles.subTitle}>Крупнейшие страховые компании</h3>
        <div className={styles.wrapperSlider}>
          <div
            ref={sliderInsuranceCompanies}
            className={cn(
              'sliderPartners',
              'keen-slider',
              styles.sliderPartners
            )}
          >
            {insuranceCompanies.map((company) => (
              <div
                key={company.title}
                className={cn(styles.slide, 'keen-slider__slide partnersSlide')}
              >
                <div className={styles.imgWrapper}>
                  <img
                    src={company.logo.big}
                    alt={`${company.title}`}
                    loading="lazy"
                  />
                </div>
                {company.title === 'Макс' && (
                  <a
                    href={maksInfo}
                    rel="noreferrer noopener"
                    target="_blank"
                    className={styles.link}
                  >
                    Подробная информация
                  </a>
                )}
              </div>
            ))}
          </div>
          {loaded && sliderInsuranceCompaniesRef && (
            <>
              <ArrowLeft
                onClick={onClickPrev(sliderInsuranceCompaniesRef)}
                disabled={onClickPrevDisabled(currentSlideCompanies)}
              />
              <ArrowRight
                onClick={onClickNext(sliderInsuranceCompaniesRef)}
                disabled={onClickNextDisabled(
                  currentSlideCompanies,
                  sliderInsuranceCompaniesRef
                )}
              />
            </>
          )}
        </div>
      </div>
    </section>
  )
}
