import React from 'react'
import cn from 'classnames'
import Modal from 'react-modal-slider'
import Partner from '../Partner'

import * as styles from './modalForPartner.module.less'
import closeSvg from '../img/close.svg'
import { videoRender } from '../TryUs'
import { useScreenSize } from '../../../hooks'

const ModalForPartner = ({
  isOpen,
  partner,
  onClose = () => {},
  onClickNext = () => {},
}) => {
  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isTablet = useScreenSize('tablet')
  const isBigTablet = useScreenSize('bigTablet')
  return (
    <Modal
      isOpen={isOpen}
      width={'590px'}
      onRequestClose={onClose}
      maxMediaWidth={1025}
      directionFrom={'right'}
    >
      <div
        key={partner.id}
        className={cn(
          styles.container,
          styles.containerModal,
          'container-padding--hr'
        )}
      >
        <button onClick={onClose} className={styles.close}>
          <img src={closeSvg} alt={'Закрыть'} loading="lazy" />
        </button>

        <div className={styles.content}>
          {partner.type !== 'video' ? (
            <Partner isModal data={partner} />
          ) : (
            videoRender(partner, isBigTablet, isTablet, isSmallTablet, isMobile)
          )}
        </div>

        {!partner.hasLast && (
          <div className={styles.next} onClick={onClickNext}>
            Следующий отзыв →
          </div>
        )}
      </div>
    </Modal>
  )
}

export default ModalForPartner
