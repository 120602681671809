// extracted by mini-css-extract-plugin
export var sidebar = "desktop-module--sidebar--a78ba";
export var step = "desktop-module--step--912c9";
export var stepActive = "desktop-module--step--active--fba75";
export var step__description = "desktop-module--step__description--4aecb";
export var step__descriptionActive = "desktop-module--step__description--active--f44e9";
export var step__order = "desktop-module--step__order--ac7ea";
export var step__text = "desktop-module--step__text--ae9dd";
export var step__title = "desktop-module--step__title--19b9d";
export var step__titleActive = "desktop-module--step__title--active--1cc8d";
export var video = "desktop-module--video--f4156";
export var videoActive = "desktop-module--video--active--00c24";
export var video__header = "desktop-module--video__header--feb1a";
export var video__wrapper = "desktop-module--video__wrapper--a144a";