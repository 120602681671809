// extracted by mini-css-extract-plugin
export var arrow = "partnerBank-module--arrow--d3f0b";
export var arrowDisabled = "partnerBank-module--arrowDisabled--bc012";
export var arrowLeft = "partnerBank-module--arrowLeft--c7a00";
export var arrowRight = "partnerBank-module--arrowRight--59f3a";
export var container = "partnerBank-module--container--39db8";
export var imgWrapper = "partnerBank-module--imgWrapper--107d8";
export var link = "partnerBank-module--link--8caf3";
export var slide = "partnerBank-module--slide--bd734";
export var sliderPartners = "partnerBank-module--sliderPartners--8a0a4";
export var subTitle = "partnerBank-module--subTitle--51675";
export var title = "partnerBank-module--title--7a663";
export var wrapper = "partnerBank-module--wrapper--bb8b3";
export var wrapperSlider = "partnerBank-module--wrapperSlider--98911";