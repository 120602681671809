import React from 'react'
import cn from 'classnames'

import firstVideo from '../video/1.mp4'
import secondVideo from '../video/2.mp4'
import thirdVideo from '../video/3.mp4'
import fourthVideo from '../video/4.mp4'
import fifthVideo from '../video/5.mp4'

import poster1 from '../img/poster1.png'
import poster2 from '../img/poster2.jpg'
import poster3 from '../img/poster3.jpg'
import poster4 from '../img/poster4.jpg'
import poster5 from '../img/poster5.jpg'

import * as styles from './videos.module.less'

const Videos = ({ step }) => {
  return (
    <>
      <video
        autoPlay
        muted
        loop
        className={cn(styles.video, { [styles.videoActive]: step === 'first' })}
        poster={poster1}
      >
        <source src={firstVideo} />
      </video>

      <video
        autoPlay
        muted
        loop
        className={cn(styles.video, {
          [styles.videoActive]: step === 'second',
        })}
      >
        <source src={secondVideo} />
      </video>

      <video
        autoPlay
        muted
        loop
        className={cn(styles.video, { [styles.videoActive]: step === 'third' })}
      >
        <source src={thirdVideo} />
      </video>

      <video
        autoPlay
        muted
        loop
        className={cn(styles.video, {
          [styles.videoActive]: step === 'fourth',
        })}
      >
        <source src={fourthVideo} />
      </video>

      <video
        autoPlay
        muted
        loop
        className={cn(styles.video, { [styles.videoActive]: step === 'fifth' })}
      >
        <source src={fifthVideo} />
      </video>
    </>
  )
}

export default Videos
