import React, { useContext } from 'react'
import cn from 'classnames'
import * as styles from './digitalMortgage.module.less'
import { cards } from './entity'
import { Card } from './Card'
import BonusContext from '../../context/bonuses'

const DigitalMortgage = () => {
  const bonuses = useContext(BonusContext)

  return (
    <section
      className={cn(styles.container, 'container-padding--hr', 'text-center')}
    >
      <div className={styles.list__wrapper}>
        <div className={styles.list}>
          {cards(bonuses).map((card, index) => (
            <Card key={index} data={card} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default DigitalMortgage
