// extracted by mini-css-extract-plugin
export var arrow = "tryUs-module--arrow--6ae22";
export var arrowDisabled = "tryUs-module--arrowDisabled--0ce20";
export var arrowLeft = "tryUs-module--arrowLeft--d7f44";
export var arrowRight = "tryUs-module--arrowRight--1a242";
export var body = "tryUs-module--body--575c6";
export var close = "tryUs-module--close--9bceb";
export var column = "tryUs-module--column--70ca5";
export var container = "tryUs-module--container--01bd9";
export var containerModal = "tryUs-module--container--modal--6645d";
export var content = "tryUs-module--content--38d61";
export var header = "tryUs-module--header--88fe6";
export var navigationWrapper = "tryUs-module--navigationWrapper--cb201";
export var next = "tryUs-module--next--ed6c8";
export var personItem = "tryUs-module--personItem--528ef";
export var personList = "tryUs-module--personList--838e0";
export var resizeButton = "tryUs-module--resizeButton--d1fce";
export var title = "tryUs-module--title--80f32";
export var tryUsDot = "tryUs-module--tryUsDot--8719c";
export var tryUsDotActive = "tryUs-module--tryUsDotActive--b3b65";
export var tryUsDots = "tryUs-module--tryUsDots--eca87";
export var tryUsSlider = "tryUs-module--tryUsSlider--35a57";
export var wrapper = "tryUs-module--wrapper--4d405";