import React from 'react'
import rustem from './img/videoPlaceholders/rustem.png'
import tatiana from './img/videoPlaceholders/tatiana.png'
import anna from './img/videoPlaceholders/anna.png'

export const partners = [
  {
    id: 0,
    type: 'person',
    iconName: 'Marina',
    social: 'Vk',
    socialUrl: 'https://vk.com/id204198054',
    name: 'Марина Губочкина',
    position: <>Бизнес-партнёр, совершивший первую сделку на платформе</>,
    text: (
      <>
        <p>
          О&nbsp;платформе узнала от&nbsp;своих бывших коллег из&nbsp;РОСБАНК
          Дом. Это банк-партнер платформы и&nbsp;там работает много моих друзей.
          Оказалось, что это как раз тот самый продукт, который ждали многие
          брокеры и агенты по&nbsp;недвижимости.
        </p>
        <p>
          Платформа Ипотека.Центр для меня, это в&nbsp;первую очередь единая
          система подачи заявок для разных банков, приоритетное рассмотрение,
          получение решений в&nbsp;кратчайшие сроки, качественное сопровождение
          кураторов и возможность согласовывать нестандартные рассмотрения
          объектов и клиентов.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          О платформе узнала от своих бывших коллег из РОСБАНК Дом. Этот
          банк-партнер платформы и там работает много моих друзей. У меня
          не было повода не доверять им.  Оказалось, что это как раз тот самый
          продукт, который ждали многие брокеры и агенты по недвижимости.
        </p>
        <p>
          Платформа Ипотека. Центр для меня, это в 1ю очередь единая система
          подачи заявок для разных банков, приоритетное рассмотрение, получение
          решений в кратчайшие сроки, качественное сопровождение кураторов
          и возможность согласовывать нестандартные рассмотрения объектов
          и клиентов. И повторюсь — сопровождение внутри системы всех клиентов
          от твоего (за это время уже ставшего близким другом) куратора, одно
          из самых значимых для меня преимуществ.
        </p>
        <p>
          Интересно то, что сервис постоянно развивается и я вместе
          с ним. Я считаю, что за Ипотека. Центр действительно будущее цифровой
          ипотеки. По крайней мере, здесь воплотили те мои представления и мечты
          о том, что должна из себя представлять цифровая ипотека.
        </p>
        <p>
          Пробуя разные сервисы, также называющими себя цифровыми я&nbsp;могу
          сказать, что здесь я&nbsp;увидела действительно оптимальный набор
          инструментов для проведения сделки от&nbsp;и&nbsp;до.
        </p>
        <p>
          Это и&nbsp;выбор банков, и&nbsp;калькулятор подсказывающий, где можно
          одобрить Клиента с&nbsp;нужными параметрами, и&nbsp;возможность заказа
          оценки и&nbsp;страховки. А&nbsp;еще понятный и&nbsp;удобный интерфейс.
          Ключевые факторы в&nbsp;работе на&nbsp;платформе для меня наверное
          все-таки&nbsp;&mdash; комфорт, профессионализм кураторов и&nbsp;легкий
          вход на&nbsp;платформу.
        </p>
        <p>
          Из&nbsp;пожеланий увидеть в&nbsp;ближайшем будущем: мобильное
          приложение Ипотека. Центр, возможность подтверждать подпись Клиента
          на&nbsp;СОГЛАСИЕ обработки персональных данных, через смс
          и&nbsp;дополнительные ресурсы для проверки кредитных историй.
        </p>
        <p>
          Работая на&nbsp;платформе с&nbsp;самого 1го дня и&nbsp;наблюдая все
          ее&nbsp;изменения уверена, что мои пожелания воплотятся в&nbsp;этом
          сервисе очень скоро. Ребята очень чутко прислушиваются
          к&nbsp;пожеланиям своих пользователей или, как здесь принято
          говорить&nbsp;&mdash; бизнес-партнеров. Это, кстати, тоже очень
          приятный нюанс&nbsp;&mdash; ощущать себя партнером цифрового
          ипотечного будущего.
        </p>
      </>
    ),
  },
  {
    id: 1,
    type: 'person',
    iconName: 'Dina',
    name: 'Дина Семенова',
    position: <>Ипотечный эксперт с опытом более 5&nbsp;лет</>,
    text: (
      <>
        <p>
          С&nbsp;2014 года помогаю людям реализовать мечту покупки своего жилья.
          За&nbsp;время своей деятельности я&nbsp;наработала свою базу
          постоянных партнеров: агентства недвижимости, частные риэлтеры,
          застройщики. К&nbsp;каждому клиенту у&nbsp;меня индивидуальный подход.
          До&nbsp;подачи заявки я&nbsp;провожу полный анализ потенциального
          заемщика и&nbsp;учитывая пожелания и&nbsp;возможности клиента подбираю
          банк-кредитор с&nbsp;лучшими...
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          &quot;С&nbsp;2014 года помогаю людям реализовать мечту покупки своего
          жилья. За&nbsp;время своей деятельности я&nbsp;наработала свою базу
          постоянных партнеров: агентства недвижимости, частные риэлтеры,
          застройщики. К&nbsp;каждому клиенту у&nbsp;меня индивидуальный подход.
          До&nbsp;подачи заявки я&nbsp;провожу полный анализ потенциального
          заемщика и&nbsp;учитывая пожелания и&nbsp;возможности клиента подбираю
          банк-кредитор с&nbsp;лучшими условиями кредитования.
        </p>
        <p>
          Сотрудничество с&nbsp;Ипотека.Центр в&nbsp;большей степени меня
          привлекает в&nbsp;плане удобства взаимодействия с&nbsp;банками.
          С&nbsp;помощью ипотечной платформы прохождение заявки в&nbsp;разы
          сократилось по&nbsp;времени, что позволило мне увеличить объем
          клиентов. Удобный интерфейс сервиса позволяет быстро завести заявку,
          а&nbsp;всплывающие подсказки позволяют легко выбрать подходящий банк
          под конкретного клиента.
        </p>
        <p>
          А&nbsp;так как, за&nbsp;каждую сделку я&nbsp;получаю бонус,
          то&nbsp;соответственно, чем больше клиентов, тем больше бонус. Это
          простая математика.
        </p>
        <p>
          Если обобщить, что для меня выделяет платформу Ипотека.Центр
          из&nbsp;всех существующих решений по&nbsp;ипотеке для брокеров,
          то&nbsp;получится такой список: Информативность,&nbsp;&mdash;
          возможность дистанционно и&nbsp;оперативно получать решения
          по&nbsp;поставленной задаче; Скорость прохождение заявки
          на&nbsp;каждом этапе; Наличие личного куратора; Высокое комиссионное
          вознаграждения за&nbsp;каждую сделку.
        </p>
        <p>
          А&nbsp;добавила&nbsp;бы я&nbsp;еще, пожалуй, возможность формирования
          кредитной истории клиента до&nbsp;подачи заявки. Это позволит
          минимизировать трудозатраты на&nbsp;недобросовестных клиентов
          и&nbsp;также учесть все долговые обязательства при расчете возможной
          суммы кредита.
        </p>
        <p>
          Всем своим партнерам рекомендую подключиться к&nbsp;платформе. Лично
          мне это позволило увеличить объемы выдачи ипотечных кредитов
          в&nbsp;несколько раз.&quot;
        </p>
      </>
    ),
  },
  {
    id: 2,
    type: 'video',
    name: 'Татьяна Ковельская',
    image: tatiana,
    link: 'https://youtu.be/WtGugDARBdA',
  },
  {
    id: 3,
    type: 'person',
    iconName: 'Ivan',
    social: 'Vk',
    socialUrl: 'https://vk.com/id67060503',
    name: 'Иван Хроменков',
    position: <>Частный риэлтор, в&nbsp;ипотеке уже 11&nbsp;лет</>,
    text: (
      <>
        <p>
          Узнал о&nbsp;платформе через друзей и&nbsp;решил попробовать. Почему?
          А&nbsp;почему&nbsp;бы и&nbsp;нет. Риэлторы каждый день в&nbsp;своей
          работе сталкиваются с&nbsp;необходимостью помочь клиенту
          с&nbsp;ипотекой. И&nbsp;мы&nbsp;это и&nbsp;так делаем во&nbsp;благо
          клиента. Каждый находит наиболее удобные способы для себя. А&nbsp;тут
          еще за&nbsp;это дают приличные бонусы, а&nbsp;не&nbsp;просто какой-то
          условный символический фикс за&nbsp;лид.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          Узнал о&nbsp;платформе через друзей и&nbsp;решил попробовать. Почему?
          А&nbsp;почему&nbsp;бы и&nbsp;нет. Риэлторы каждый день в&nbsp;своей
          работе сталкиваются с&nbsp;необходимостью помочь клиенту
          с&nbsp;ипотекой. И&nbsp;мы&nbsp;это и&nbsp;так делаем во&nbsp;благо
          клиента. Каждый находит наиболее удобные способы для себя. А&nbsp;тут
          еще за&nbsp;это дают приличные бонусы, а&nbsp;не&nbsp;просто какой-то
          условный символический фикс за&nbsp;лид.
        </p>
        <p>
          И&nbsp;не&nbsp;нужно ходить по&nbsp;разным банкам
          и&nbsp;их&nbsp;сайтам в&nbsp;поисках необходимой информации,
          налаживать контакты с&nbsp;каждым менеджером в&nbsp;отдельности,
          контролировать постоянно все эти процессы в&nbsp;разных инстанциях.
          У&nbsp;нас&nbsp;же не&nbsp;один клиент. А&nbsp;тут одно окно
          и&nbsp;все под контролем. Удобно.
        </p>
        <p>
          А&nbsp;еще нравится, что разработчики постоянно обновляют систему
          и&nbsp;собирают обратную связь. Поэтому надеюсь, что мой отзыв ляжет
          в&nbsp;основу будущих усовершенствований. Ребят, хочу поскорее, чтобы
          вышло ваше мобильное приложение! Вот это будет просто огонь! Пока
          пользуюсь через телефон с&nbsp;браузера в&nbsp;десктопной версии. Тоже
          все работает, но&nbsp;не&nbsp;так удобно.
        </p>
        <p>
          Ну&nbsp;и&nbsp;нет предела совершенству&nbsp;&mdash; предлагаю еще
          больше ускорить время получения информации по&nbsp;доработкам заявок.
          Чтобы прямо отправил и&nbsp;через несколько минут получил.
          Я&nbsp;в&nbsp;вас верю.
        </p>
        <p>
          Ну&nbsp;а&nbsp;вообще я&nbsp;пока таких сервисов еще не&nbsp;видел
          на&nbsp;рынке, чтобы весь цикл сделки был онлайн,
          а&nbsp;не&nbsp;только заявка. И&nbsp;чтобы прозрачно&nbsp;&mdash;
          видишь в&nbsp;реальном времени и&nbsp;ставки клиента, и&nbsp;его
          платежи, и&nbsp;свою приятную комиссию. И&nbsp;все важные оповещения
          по&nbsp;изменению статуса заявки на&nbsp;почту приходят, что можно
          оперативно мониторить через телефон.
        </p>
      </>
    ),
  },
  {
    id: 4,
    type: 'video',
    image: rustem,
    name: 'Рустем Барсов',
    link: 'https://youtu.be/e1pAazFgLfI',
  },
  {
    id: 5,
    type: 'person',
    iconName: 'Valentina',
    name: 'Валентина Барышникова',
    position: <>Ипотечный эксперт</>,
    text: (
      <>
        <p>
          Я&nbsp;работаю с&nbsp;платформой всего пару месяцев. Узнала
          об&nbsp;Ипотека.Центр от&nbsp;знакомой, которая работает ипотечным
          брокером уже 2&nbsp;года. Мой опыт в&nbsp;ипотеке, пока, совсем
          небольшой. Три года я&nbsp;занимаюсь продажей недвижимости, встал
          вопрос о&nbsp;поисках дополнительных способов заработка. У&nbsp;меня
          был опыт работы с&nbsp;другой похожей платформой, но&nbsp;на&nbsp;ней
          нет такого интуитивно понятного интерфейса, как на&nbsp;платформе
          Ипотека.Центр. Мне нравится удалённая работа и&nbsp;моя сфера
          полностью меня в&nbsp;этом плане обеспечивает трафиком клиентов,
          которым...
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          Я&nbsp;работаю с&nbsp;платформой всего пару месяцев. Узнала
          об&nbsp;Ипотека.Центр от знакомой, которая работает ипотечным брокером
          уже 2&nbsp;года. Мой опыт в&nbsp;ипотеке, пока, совсем небольшой. Три
          года я&nbsp;занимаюсь продажей недвижимости, встал вопрос
          о&nbsp;поисках дополнительных способов заработка. У&nbsp;меня был опыт
          работы с&nbsp;другой похожей платформой, но&nbsp;на ней нет такого
          интуитивно понятного интерфейса, как на&nbsp;платформе Ипотека.Центр.
          Мне нравится удалённая работа и&nbsp;моя сфера полностью меня
          в&nbsp;этом плане обеспечивает трафиком клиентов, которым я&nbsp;могу
          предложить разные ипотечные продукты.
        </p>
        <p>
          Платформа даёт возможность заработать, и&nbsp;помогает идти чётко
          по&nbsp;всем этапам и&nbsp;ничего не&nbsp;упустить. Куратор,
          с&nbsp;которым я&nbsp;работаю, всегда готова помочь в&nbsp;решении
          самых сложных задач.
        </p>
        <p>
          Я&nbsp;понимаю, что важный критерий для подключения банков
          партнёров&nbsp;&mdash; это размер вознаграждения за&nbsp;проведенные
          сделки, которое банки готовы давать. Не&nbsp;все готовы работать
          по&nbsp;партнерской схеме. Жаль, конечно, что пока таких банков
          на&nbsp;платформе не&nbsp;много, но&nbsp;и&nbsp;работать бесплатно,
          признаться, не&nbsp;хочется. Так что пусть лучше банков меньше,
          а&nbsp;бонус больше. Главное, что текущие программы банков полностью
          покрывают потребности клиентов.
        </p>
        <p>
          Единственное, пока не&nbsp;хватает сервиса предварительной проверки
          кредитной истории. Многие клиенты признаются сразу, что кредитный
          рейтинг у&nbsp;них низкий, а&nbsp;заполнение анкеты и&nbsp;подача
          в&nbsp;банк занимает большое количество времени.
          Ну&nbsp;и&nbsp;в&nbsp;условиях карантина не&nbsp;хватает чего-то вроде
          цифровой подписи для анкет, т.к.&nbsp;не&nbsp;у&nbsp;всех дома есть
          сканер, принтер и&nbsp;пр. Но&nbsp;с&nbsp;той скоростью, как здесь
          на&nbsp;платформе появляется всё новые функции, думаю, что и&nbsp;это
          не&nbsp;за&nbsp;горами.&quot;
        </p>
      </>
    ),
  },
  {
    id: 6,
    type: 'person',
    iconName: 'Sergey',
    social: 'Vk',
    socialUrl: 'https://vk.com/shvidkiy_sergey',
    name: 'Сергей Швыдкий',
    position: <>Ипотечный эксперт</>,
    text: (
      <>
        <p>
          &laquo;Являюсь ипотечным специалистом более трех лет. Начинал свою
          деятельность в&nbsp;банке, курировал различные агентства недвижимости
          Санкт-Петербурга по&nbsp;направлению ипотеки. Говоря откровенно,
          в&nbsp;определенный момент условия работы стали сильно демотивировать.
          Я&nbsp;решил попробовать поработать на&nbsp;себя, как частный
          ипотечный брокер. С&nbsp;тех пор ключевые партнеры продолжают работу
          со&nbsp;мной, а&nbsp;&laquo;сарафанное радио&raquo; увеличивает поток
          обращений по&nbsp;вопросам согласования ипотеки. Потребности своих
          клиентов я&nbsp;полностью закрываю, благодаря команде Ипотека.Центр!
          Платформу, я&nbsp;по&nbsp;счастливой случайности обнаружил
          на&nbsp;просторах интернета.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          &laquo;Являюсь ипотечным специалистом более трех лет. Начинал свою
          деятельность в&nbsp;банке, курировал различные агентства недвижимости
          Санкт-Петербурга по&nbsp;направлению ипотеки. Говоря откровенно,
          в&nbsp;определенный момент условия работы стали сильно демотивировать.
          Я&nbsp;решил попробовать поработать на&nbsp;себя, как частный
          ипотечный брокер. С&nbsp;тех пор ключевые партнеры продолжают работу
          со&nbsp;мной, а&nbsp;&laquo;сарафанное радио&raquo; увеличивает поток
          обращений по&nbsp;вопросам согласования ипотеки. Потребности своих
          клиентов я&nbsp;полностью закрываю, благодаря команде Ипотека.Центр!
          Платформу, я&nbsp;по&nbsp;счастливой случайности обнаружил
          на&nbsp;просторах интернета.{' '}
        </p>
        <p>
          С&nbsp;Ипотека.Центр, я&nbsp;начал активное сотрудничество
          с&nbsp;начала 2020&nbsp;года, и&nbsp;с&nbsp;первых дней взаимодействия
          было понятно, что компания нацелена на&nbsp;результат
          и&nbsp;качественную работу. Особенно хочу выделить, поддержку куратора
          в&nbsp;решении сложных задач. В&nbsp;сфере ипотеки поток срочных
          вопросов бесконечный и&nbsp;очень важно понимать, что тебе есть кому
          помочь. Не&nbsp;вооруженным глазом видно, что сама платформа
          и&nbsp;ее&nbsp;интерфейс меняется в&nbsp;лучшую сторону, почти
          ежедневно. В&nbsp;тот момент, когда банкиры и&nbsp;коллеги риелторы,
          в&nbsp;условиях карантина испытывали трудности, так как не&nbsp;были
          готовы к&nbsp;удаленной работе, я&nbsp;активно продолжал проводить
          сделки, ведь платформа Ипотека.Центр может проводить весь цикл сделки
          полностью онлайн.{' '}
        </p>
        <p>
          От&nbsp;других данная платформа отличается максимальной прозрачностью
          хода событий и&nbsp;оперативностью обработки информации. При первом
          знакомстве с&nbsp;платформой хорошо запомнил фразу: &laquo;Зарабатывай
          из&nbsp;любой точки мира&raquo;. Это оказалось не&nbsp;пустым
          рекламным слоганом, а&nbsp;реальным фактом, который считаю самым
          крутым в&nbsp;данном деле. Одно, и, наверное, единственное желание
          по&nbsp;доработке и&nbsp;внедрению в&nbsp;программу, является
          подтверждение согласия на&nbsp;обработку персональных данных
          в&nbsp;письменном виде со&nbsp;стороны клиента. Знаю, что
          в&nbsp;некоторых банках есть вид данного согласия через смс.
        </p>
        <p>
          В&nbsp;целом ипотечной сфере еще есть куда стремиться в&nbsp;вопросе
          технологий, и&nbsp;здорово быть частью тех, кто задает эти тренды
          вместе с&nbsp;Ипотека.Центр и&nbsp;другими игроками рынка.&quot;
        </p>
      </>
    ),
  },
  {
    id: 7,
    type: 'person',
    iconName: 'Anastasia',
    name: 'Анастасия Поликарпова',
    position: <>Риэлтор с 10-летним стажем</>,
    text: (
      <>
        <p>
          Работаю на&nbsp;платформе примерно с&nbsp;января 2020&nbsp;г.
          Я&nbsp;долго и&nbsp;целенаправленно искала партнера
          с&nbsp;эксклюзивными условиями от&nbsp;банков.
        </p>
        <p>
          Мне очень удобно работать через сервис одного окна, не&nbsp;надо
          ежемесячно носиться с&nbsp;закрывающими документами, следить
          за&nbsp;изменением ставок, искать анкеты банков и&nbsp;перепроверять
          их&nbsp;актуальность.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          Работаю на&nbsp;платформе примерно с&nbsp;января 2020&nbsp;г.
          Я&nbsp;долго и&nbsp;целенаправленно искала партнера
          с&nbsp;эксклюзивными условиями от&nbsp;банков.
        </p>
        <p>
          Мне очень удобно работать через сервис одного окна, не&nbsp;надо
          ежемесячно носиться с&nbsp;закрывающими документами, следить
          за&nbsp;изменением ставок, искать анкеты банков и&nbsp;перепроверять
          их&nbsp;актуальность.
        </p>
        <p>
          Я&nbsp;и&nbsp;раньше работала удаленно, но&nbsp;с&nbsp;Ипотека.Центр
          мне стало работать еще удобнее. ⠀ ⠀
        </p>
        <p>
          Очень полезна помощь куратора, если я&nbsp;в&nbsp;затруднении, всегда
          можно получить ответ на&nbsp;сложный вопрос и&nbsp;подсказку
          по&nbsp;документам. Вебинары &laquo;Банковская среда&raquo; однозначно
          для меня были очень полезны. Особенно&nbsp;то, что можно было задать
          вопрос напрямую банку и&nbsp;получить нужную информацию из&nbsp;первых
          уст.
        </p>
        <p>
          А&nbsp;правильный ответ на&nbsp;один из&nbsp;вопросов ведущих принес
          мне полезный подарок, который очень пригодился. Я&nbsp;живу
          в&nbsp;Пушкине (Ленинградская область), в&nbsp;окружении парков
          и&nbsp;люблю пешие прогулки, умный шагомер&nbsp;&mdash; то, что надо.
        </p>
        <p>
          Если говорить о&nbsp;мероприятиях, то&nbsp;в&nbsp;будущем мне
          был&nbsp;бы интересен формат смежных тем. Например, инвестиции
          в&nbsp;недвижимость.В последнее время заметила, что заявки стали чуть
          дольше рассматриваться. Понимаю, что это может быть связано
          с&nbsp;загруженностью банков, и&nbsp;коллеги постоянно информируют
          об&nbsp;изменении ситуации, но&nbsp;хотелось&nbsp;бы еще быстрее,
          дальше, сильнее и&nbsp;только вперед!&quot;
        </p>
      </>
    ),
  },
  {
    id: 9,
    type: 'person',
    iconName: 'Olesya',
    name: 'Олеся Нугуманова',
    position: <>Риэлтор с 15-летним стажем</>,
    text: (
      <>
        <p>
          С&nbsp;2012 года я&nbsp;занималась приемом заявок в&nbsp;банке,
          а&nbsp;с&nbsp;2015 года стала работать самостоятельно, как ипотечные
          брокер. Узнала о&nbsp;Ипотека.Центр через Инстаграм&nbsp;&mdash;
          просто увидела пост в&nbsp;ленте и&nbsp;заинтересовалась. Позвонила,
          меня проконсультировали.
        </p>
        <p>Платформа очень помогает! Я работаю удаленно...</p>
      </>
    ),
    fullText: (
      <>
        <p>
          С&nbsp;2012 года я&nbsp;занималась приемом заявок в&nbsp;банке,
          а&nbsp;с&nbsp;2015 года стала работать самостоятельно, как ипотечные
          брокер. Узнала о&nbsp;Ипотека.Центр через Инстаграм&nbsp;&mdash;
          просто увидела пост в&nbsp;ленте и&nbsp;заинтересовалась. Позвонила,
          меня проконсультировали.
        </p>
        <p>
          Платформа очень помогает! Я&nbsp;работаю удаленно, живу сейчас
          на&nbsp;Алтае, а&nbsp;сделки у&nbsp;меня проходят
          и&nbsp;в&nbsp;Москве, и&nbsp;в&nbsp;Санкт-Петербурге, то&nbsp;есть все
          провожу дистанционно.
        </p>
        <p>
          Очень нравится сам проект. До&nbsp;этого я&nbsp;имела дело
          с&nbsp;другими сервисами, мне есть с&nbsp;чем сравнить.
        </p>
        <p>
          Самое крутое у&nbsp;Ипотека.Центр&nbsp;&mdash; это прозрачность. То,
          что всегда вовремя платят комиссионные вознаграждения! Я&nbsp;ушла
          со&nbsp;всех платформ именно потому, что свои бонусы получала спустя
          три или четыре месяца после сделок. В&nbsp;одной компании мне так
          и&nbsp;не&nbsp;выплатили приличную сумму.
        </p>
        <p>
          Здорово, что на&nbsp;платформу добавились еще банки&nbsp;&mdash; это
          позволяет охватить больший процент клиентов. Они всегда разные,
          соответственно, и&nbsp;запросы очень отличаются. Раньше приходилось
          в&nbsp;дополнительные банки отправлять все напрямую, а&nbsp;сейчас
          на&nbsp;90% обхожусь платформой Ипотека.Центр.
        </p>
        <p>
          Мне безумно нравится поддержка. Было приятно работать с&nbsp;моим
          куратором Александром Михайловым. Многие вопросы помогал решать.
          Александр молодец! Очень рада, что он&nbsp;растет профессионально,
          но&nbsp;его советов мне не&nbsp;хватает. Начинаю работать с&nbsp;новым
          куратором.
        </p>
        <p>
          Очень жду расширенного функционала CRM, надеюсь скоро всё появится!
          Немного смущает длинная анкета&nbsp;&mdash; на&nbsp;одиннадцать
          листов, но&nbsp;понимаю, что это требования банков
        </p>
        <p>
          А&nbsp;в&nbsp;целом все замечательно! Я&nbsp;рада, что компания
          растет, добавляются банки и&nbsp;комиссия хорошая. По&nbsp;факту
          на&nbsp;платформе мне нравится все&raquo;.
        </p>
      </>
    ),
  },
  {
    id: 10,
    type: 'video',
    image: anna,
    name: 'Анна Георгиевская',
    link: 'https://youtu.be/o3eqCHmjNhA',
  },
  {
    id: 11,
    type: 'person',
    iconName: 'Aleksandr',
    name: 'Александр Кудинов',
    position: <>Риэлтор</>,
    text: (
      <>
        <p>
          Некоторое время назад присутствовал на&nbsp;вручении премии Лучший
          ипотечный эксперт России 2019.
        </p>
        <p>
          Премию вручает компания Ипотека Центр. Эти уважаемые господа создали
          ипотечный калькулятор, который делает запрос по&nbsp;ипотеке
          в&nbsp;ряд крупнейших банков России, и&nbsp;обучили брокеров работать
          с&nbsp;их&nbsp;сервисом на&nbsp;партнерских условия.
        </p>
        <p>
          Мы все любим цифры и&nbsp;факты, вот для Вас занимательный
          факт&nbsp;&mdash; в&nbsp;2019&nbsp;году, через сервис Ипотека Центра
          было выдано ипотеки более чем
          на&nbsp;6&nbsp;000&nbsp;000&nbsp;000&nbsp;рублей!
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          Некоторое время назад присутствовал на&nbsp;вручении премии Лучший
          ипотечный эксперт России 2019.
        </p>
        <p>
          Премию вручает компания Ипотека Центр. Эти уважаемые господа создали
          ипотечный калькулятор, который делает запрос по&nbsp;ипотеке
          в&nbsp;ряд крупнейших банков России, и&nbsp;обучили брокеров работать
          с&nbsp;их&nbsp;сервисом на&nbsp;партнерских условия.
        </p>
        <p>
          Мы все любим цифры и&nbsp;факты, вот для Вас занимательный
          факт&nbsp;&mdash; в&nbsp;2019&nbsp;году, через сервис Ипотека Центра
          было выдано ипотеки более чем
          на&nbsp;6&nbsp;000&nbsp;000&nbsp;000&nbsp;рублей!
        </p>
        <p>
          Я проходил их&nbsp;обучение, и&nbsp;могу одобрить Вам ипотеку
          на&nbsp;выгодных условиях
        </p>
        <p>
          В этот вечер&nbsp;я, к&nbsp;сожалению, не&nbsp;получил свою награду,
          но&nbsp;у&nbsp;меня далеко идущие планы на&nbsp;2020&nbsp;год. Зато
          я&nbsp;выпил шампанского, пообщался с&nbsp;интересными людьми
          и&nbsp;поставил для себя еще одну цель!&quot;
        </p>
      </>
    ),
  },
  {
    id: 12,
    type: 'person',
    iconName: 'Tatiana',
    name: 'Татьяна Кылчик',
    position: <>Риэлтор с 10-летним стажем</>,
    text: (
      <>
        <p>
          На&nbsp;платформе работаю недавно, около 3-4&nbsp;месяцев. Очень
          удобно для таких, как я&nbsp;ипотечных брокеров&nbsp;&mdash; новичков,
          хоть я&nbsp;и&nbsp;риелтор с&nbsp;10&nbsp;летним стажем.
        </p>
        <p>
          Всегда ипотека была для меня очень сложным продуктом с&nbsp;кучей
          нюансов и&nbsp;тонкостей, в&nbsp;которых сложно разобраться
          с&nbsp;первого раза.
        </p>
        <p>
          Благодаря платформе для меня стало намного проще и&nbsp;удобнее
          работать с&nbsp;ипотекой.
        </p>
        <p>
          Большой плюс также в&nbsp;том, что не&nbsp;нужно никуда ехать, все
          можно сделать удаленно.
        </p>
      </>
    ),
    tabletText: (
      <>
        <p>
          На&nbsp;платформе работаю недавно, около 3-4&nbsp;месяцев. Очень
          удобно для таких, как я&nbsp;ипотечных брокеров&nbsp;&mdash; новичков,
          хоть я&nbsp;и&nbsp;риелтор с&nbsp;10&nbsp;летним стажем.
        </p>
        <p>
          Всегда ипотека была для меня очень сложным продуктом с&nbsp;кучей
          нюансов и&nbsp;тонкостей, в&nbsp;которых сложно разобраться
          с&nbsp;первого раза.
        </p>
        <p>
          Благодаря платформе для меня стало намного проще и&nbsp;удобнее
          работать с&nbsp;ипотекой.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          На&nbsp;платформе работаю недавно, около 3-4&nbsp;месяцев. Очень
          удобно для таких, как я&nbsp;ипотечных брокеров&nbsp;&mdash; новичков,
          хоть я&nbsp;и&nbsp;риелтор с&nbsp;10&nbsp;летним стажем.
        </p>
        <p>
          Всегда ипотека была для меня очень сложным продуктом с&nbsp;кучей
          нюансов и&nbsp;тонкостей, в&nbsp;которых сложно разобраться
          с&nbsp;первого раза.
        </p>
        <p>
          Благодаря платформе для меня стало намного проще и&nbsp;удобнее
          работать с&nbsp;ипотекой. Большой плюс также в&nbsp;том, что
          не&nbsp;нужно никуда ехать, все можно сделать удаленно. ⠀
        </p>
        <p>
          Хочу отметить, что в&nbsp;отличие от&nbsp;других подобных сервисов,
          именно в&nbsp;Ипотека.Центр есть личный куратор, который со&nbsp;мной
          на&nbsp;связи и&nbsp;готов ответить на&nbsp;самые сложные вопросы.
          Он&nbsp;также как и&nbsp;я, заинтересован в&nbsp;положительных
          решениях банков и&nbsp;быстром выводе клиентов на&nbsp;сделку.
        </p>
        <p>
          В&nbsp;целом я&nbsp;очень довольна платформой, она полностью отвечает
          моим рабочим задачам. Из&nbsp;предложений могу, наверное, назвать
          только еще одно&nbsp;&mdash; по-моему, было&nbsp;бы очень удобно,
          если&nbsp;бы после одобрения на&nbsp;связи был&nbsp;бы ещё
          и&nbsp;сотрудник банка, иногда от&nbsp;них очень долго приходится
          ждать обратную связь.&quot;
        </p>
      </>
    ),
  },
  {
    id: 13,
    type: 'person',
    iconName: 'Daria',
    name: 'Дарья Крутикова',
    position: <>Риэлтор с 10-летним стажем</>,
    text: (
      <>
        <p>
          Я&nbsp;зарегистрировалась на&nbsp;платформе довольно давно, но&nbsp;
          первый кредит выдала только в&nbsp;июне 2020. Карантин стал для меня
          катализатором перестройки на&nbsp;удаленную работу. Узнала о&nbsp;
          сервисе от&nbsp;своих бывших коллег по&nbsp;банку ДельтаКредит,
          которые сейчас являются кураторами на&nbsp;платформе Ипотека.Центр.
        </p>
        <p>
          Могу сказать, что в&nbsp;Ипотека.Центр сейчас собралась звездная
          команда профессионалов в&nbsp;области недвижимости и&nbsp;ипотечного
          кредитования. Конечно, когда встал вопрос о&nbsp;том, к&nbsp;кому
          обратиться&nbsp;&mdash; на&nbsp;ум&nbsp;пришла именно эта компания
          и&nbsp;люди, с&nbsp;которыми проработала бок о&nbsp;бок 8&nbsp;лет.
          Доверие к&nbsp;команде 100% во&nbsp;всем, поэтому не&nbsp;задумываясь
          пришла сюда. Самое крутое на&nbsp;платформе&nbsp;&mdash; люди. Команда
          кураторов и&nbsp;айтишников, ребята, вы&nbsp;лучшие!
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          &quot;Я&nbsp;зарегистрировалась на&nbsp;платформе довольно давно,
          но&nbsp;первый кредит выдала только в&nbsp;июне 2020. Карантин стал
          для меня катализатором перестройки на&nbsp;удаленную работу. Узнала
          о&nbsp;сервисе от&nbsp;своих бывших коллег по&nbsp;банку ДельтаКредит,
          которые сейчас являются кураторами на&nbsp;платформе Ипотека.Центр.
        </p>
        <p>
          Могу сказать, что в&nbsp;Ипотека.Центр сейчас собралась звездная
          команда профессионалов в&nbsp;области недвижимости и&nbsp;ипотечного
          кредитования. Конечно, когда встал вопрос о&nbsp;том, к&nbsp;кому
          обратиться&nbsp;&mdash; на&nbsp;ум&nbsp;пришла именно эта компания
          и&nbsp;люди, с&nbsp;которыми проработала бок о&nbsp;бок 8&nbsp;лет.
          Доверие к&nbsp;команде 100% во&nbsp;всем, поэтому не&nbsp;задумываясь
          пришла сюда. Самое крутое на&nbsp;платформе&nbsp;&mdash; люди. Команда
          кураторов и&nbsp;айтишников, ребята, вы&nbsp;лучшие! ⠀
        </p>
        <p>
          Платформа в&nbsp;плане работы гениальна. Максимально прозрачен весь
          процесс от&nbsp;одобрения до&nbsp;сделки. Можно подать заявку сразу
          в&nbsp;несколько банков. При наличии вопросов есть чат. Ответы
          приходят в&nbsp;течение нескольких часов, что я&nbsp;лично считаю
          супер-сервисом. Документы можно подгрузить буквально одним кликом.
          Платформа ни&nbsp;разу не&nbsp;зависла за&nbsp;все время
          использования. Банк выбираешь не&nbsp;только по&nbsp;%&nbsp;ставке,
          но&nbsp;и&nbsp;по&nbsp;комиссии, которую можешь получить при выдаче
          кредита. Сам факт того, что процесс получения комиссии наконец-то
          &laquo;обелился&raquo;, меня как человека, ратующего за&nbsp;честный
          подход без подковёрных игр, порадовал больше всего. Еще мне очень
          понравилась линеечка, показывающая статус заявки в&nbsp;личном
          кабинете. Такая простая геймификация процесса, а&nbsp;как приятно, что
          и&nbsp;об&nbsp;этом позаботились.⠀
        </p>
        <p>
          Я&nbsp;других аналогов Ипотека.Центр даже и&nbsp;привести
          не&nbsp;могу. Да&nbsp;и&nbsp;изучать их&nbsp;не&nbsp;хочется.
          Я&nbsp;приверженец профессионального подхода и&nbsp;премиального
          сервиса. Это именно&nbsp;то, чем характеризуется данный проект.
        </p>
        <p>
          Мне очень комфортно работать &laquo;на&nbsp;удаленке&raquo;
          и&nbsp;нравится, что платформа позволяет решить все вопросы без очного
          общения. Я&nbsp;очень ценю время как ресурс, потому что могу его
          использовать на&nbsp;решение многих задач и&nbsp;личных дел. Может
          кощунственно прозвучать, но&nbsp;я&nbsp;очень рада карантину
          и&nbsp;самоизоляции. Я&nbsp;повысила свою продуктивность в&nbsp;разы
          и&nbsp;еще осталось время для личных дел. Платформа Ипотека.Центр как
          раз сильно помогла в&nbsp;этом.
        </p>
        <p>
          С&nbsp;нетерпением жду мобильной версии платформы. Это точно еще
          больше ускорит процесс одобрения заявок ввиду того, что клиенты любят
          присылать документы в&nbsp;мессенджерах. И&nbsp;с&nbsp;телефона
          их&nbsp;станет еще удобнее загружать и&nbsp;использовать для
          работы.&quot;
        </p>
      </>
    ),
  },
  {
    id: 14,
    type: 'person',
    iconName: 'Pavel',
    name: 'Павел Костин',
    position: <>Риэлтор</>,
    text: (
      <>
        <p>
          В&nbsp;банковской сфере я&nbsp;с&nbsp;2014&nbsp;года. Опыт
          в&nbsp;кредитовании больше 5&nbsp;лет, ипотекой занимаюсь год
          с&nbsp;лишним. Для меня услуги ипотечного агента&nbsp;&mdash; это
          не&nbsp;основной вид занятости, поэтому очень интересен формат
          удаленной работы. На&nbsp;платформе все процессы автоматизированы,
          в&nbsp;личный кабинет можно зайти с&nbsp;любого компьютера.
          И&nbsp;не&nbsp;важно, дома&nbsp;ты, на&nbsp;работе или в&nbsp;отпуске.
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          В&nbsp;банковской сфере я&nbsp;с&nbsp;2014&nbsp;года. Опыт
          в&nbsp;кредитовании больше 5&nbsp;лет, ипотекой занимаюсь год
          с&nbsp;лишним. Для меня услуги ипотечного агента&nbsp;&mdash; это
          не&nbsp;основной вид занятости, поэтому очень интересен формат
          удаленной работы. На&nbsp;платформе все процессы автоматизированы,
          в&nbsp;личный кабинет можно зайти с&nbsp;любого компьютера.
          И&nbsp;не&nbsp;важно, дома&nbsp;ты, на&nbsp;работе или в&nbsp;отпуске.
        </p>
        <p>
          К&nbsp;платформе Ипотека.Центр я&nbsp;подключился в&nbsp;мае 2020
          года&nbsp;&mdash; узнал о&nbsp;ней от&nbsp;бывшего коллеги. Такой вид
          партнерства стал для меня открытием, удачной находкой! Ни&nbsp;поездок
          в&nbsp;банк, ни&nbsp;долгих ожиданий у&nbsp;телефона. Всегда можно
          получить обратную связь от&nbsp;своего куратора.
        </p>
        <p>
          Среди похожих платформ, которые у&nbsp;всех на&nbsp;слуху,
          Ипотека.Центр выгодно отличается условиями мотивации своих партнеров.
          Во-первых, бонусы выплачивают быстро и&nbsp;своевременно, и&nbsp;это
          в&nbsp;нынешнее время очень важно. Во-вторых, есть повышающий
          коэффициент. Больше выдал ипотечных кредитов&nbsp;&mdash; больше
          заработал. Очень здорово мотивирует!
        </p>
        <p>
          Если говорить об&nbsp;улучшении сервиса, то&nbsp;иногда
          не&nbsp;хватает полной картины того, как движется заявка. И&nbsp;очень
          хотелось&nbsp;бы приложение для мобильных устройств.
          А&nbsp;в&nbsp;остальном на&nbsp;платформе очень здорово все
          устроено&quot;.
        </p>
      </>
    ),
  },
  {
    id: 15,
    type: 'person',
    iconName: 'Anna',
    name: 'Анна Георгиевская',
    hasLast: true,
    position: <>Эксперт по ипотеке</>,
    text: (
      <>
        <p>
          Я&nbsp;всегда хотела работать удаленно. Доходило до&nbsp;того, что
          в&nbsp;офисе я&nbsp;просто не&nbsp;чувствовала себя полноценной: эти
          рамки &laquo;с&nbsp;9&nbsp;до&nbsp;18&raquo; мне очень мешали.
          Начинала свою ипотечную карьеру я&nbsp;помощником в&nbsp;агентстве
          недвижимости. Я&nbsp;была там сразу всем&nbsp;&mdash;
          и&nbsp;помощником, и&nbsp;ипотечником. Постепенно я&nbsp;полностью
          перешла в&nbsp;ипотеку, потому что это мое. А&nbsp;офис&nbsp;&mdash;
          нет =)
        </p>
      </>
    ),
    fullText: (
      <>
        <p>
          &laquo;Я&nbsp;всегда хотела работать удаленно. Доходило до&nbsp;того,
          что в&nbsp;офисе я&nbsp;просто не&nbsp;чувствовала себя полноценной:
          эти рамки &laquo;с&nbsp;9&nbsp;до&nbsp;18&raquo; мне очень мешали.
          Начинала свою ипотечную карьеру я&nbsp;помощником в&nbsp;агентстве
          недвижимости. Я&nbsp;была там сразу всем&nbsp;&mdash;
          и&nbsp;помощником, и&nbsp;ипотечником. Постепенно я&nbsp;полностью
          перешла в&nbsp;ипотеку, потому что это мое. А&nbsp;офис&nbsp;&mdash;
          нет =)
        </p>
        <p>
          Сейчас кайфую я&nbsp;и&nbsp;мои клиенты. Да, график ненормированный,
          но&nbsp;я&nbsp;сама его планирую и&nbsp;распределяю время так, как
          комфортно мне. А&nbsp;мои клиенты получают ту&nbsp;ставку
          и&nbsp;ту&nbsp;квартиру, о&nbsp;которой мечтают. Они говорят
          мне&nbsp;&mdash; ты&nbsp;крутая! И&nbsp;это высший пилотаж эмоций для
          меня. Я&nbsp;готова ради этого на&nbsp;любые подвиги.
        </p>
        <p>
          Для меня показательно, что мои клиенты считают меня экспертом. Они
          обращаются ко&nbsp;мне с&nbsp;любыми вопросами по&nbsp;ипотеке
          и&nbsp;всегда уверены, что я&nbsp;знаю всё. А&nbsp;я&nbsp;в&nbsp;свою
          очередь уверена, что на&nbsp;любой вопрос всегда ответит мой куратор
          на&nbsp;платформе Ипотека.Центр. И&nbsp;важно, что куратор постоянный!
        </p>
        <p>
          Мне есть с&nbsp;чем сравнивать, ведь ад&nbsp;для ипотечного
          брокера&nbsp;&mdash; постоянная смена кураторов в&nbsp;банках, когда
          раз за&nbsp;разом приходиться погружать все новых людей в&nbsp;нюансы
          своего вопроса.
        </p>
        <p>
          Сейчас я&nbsp;работаю только по&nbsp;рекомендациям. Так получается
          благодаря тому, что по&nbsp;завершению сделки с&nbsp;клиентом,
          он&nbsp;довольный идет &laquo;хвастаться&raquo; своим друзья
          и&nbsp;коллегам. Это и&nbsp;есть мой секрет. За&nbsp;период моей
          деятельности на&nbsp;платформе накопилось достаточное количество таких
          клиентов, и&nbsp;мы&nbsp;закрыли несколько сделок буквально
          за&nbsp;пару дней.
        </p>
        <p>
          Я&nbsp;делаю свою работы от&nbsp;сердца. Для меня важно, чтобы каждый
          клиент запомнил меня и&nbsp;то, что я&nbsp;ему действительно помогла.
          Порой, мне говорят, что я&nbsp;ношусь с&nbsp;клиентами, как
          с&nbsp;детьми. А&nbsp;для меня это совершенно
          естественно!Ипотека&nbsp;&mdash; это&nbsp;же целый сложный мир. Как
          по-вашему врач-хирург, например, сможет сам быстро разобраться
          во&nbsp;всех нюансах, деталях, круговороте документов, найти лучшее
          предложение? Он&nbsp;профессионал в&nbsp;своем деле.
          А&nbsp;я&nbsp;в&nbsp;своем. И&nbsp;благодаря платформе Ипотека.Центр
          сложные процессы становятся более прозрачными, быстрыми, как
          и&nbsp;должно быть в&nbsp;современном диджитал-мире.
        </p>
        <p>
          Если работать по&nbsp;принципу&nbsp;&mdash; выдать и&nbsp;забыть,
          то&nbsp;рекомендаций не&nbsp;случится, это надо понимать. Например,
          у&nbsp;меня есть 2&nbsp;агента, которые работают исключительно
          со&nbsp;мной.
        </p>
        <p>
          И&nbsp;еще, если уж&nbsp;делиться лайфхаками, то&nbsp;могу сказать,
          что всегда нужно помнить про своих старых клиентов. Лично
          я&nbsp;со&nbsp;всеми уже прошла как минимум по&nbsp;2&nbsp;круга
          ипотеки, а&nbsp;с&nbsp;кем-то идем на&nbsp;3-й и&nbsp;4-й виражи.
          Рефинансирование наше всё!
        </p>
        <p>
          Ну&nbsp;и&nbsp;важно не&nbsp;забывать про творчество. Я&nbsp;помимо
          всего прочего занимаюсь керамикой. Развиваю левое и&nbsp;правое
          полушария! В&nbsp;рамках офисной работы это было&nbsp;бы нереально,
          увы. И&nbsp;результат мой по&nbsp;выдачам никогда не&nbsp;был таким
          высоким, как сейчас.&quot;
        </p>
      </>
    ),
  },
]
