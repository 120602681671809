// extracted by mini-css-extract-plugin
export var dNone = "video-module--d-none--f5f3c";
export var duration = "video-module--duration--694da";
export var inlineText = "video-module--inlineText--c1992";
export var inlineTitle = "video-module--inlineTitle--f47f6";
export var playButton = "video-module--playButton--3fcd3";
export var popup = "video-module--popup--93426";
export var popupClose = "video-module--popup-close--b3a30";
export var popupOverlay = "video-module--popup-overlay--9ffc2";
export var popupPlayer = "video-module--popup-player--ddba0";
export var videoCard = "video-module--videoCard--56324";
export var videoDescription = "video-module--videoDescription--7dc3c";
export var videoList = "video-module--videoList--48db4";
export var videoPreview = "video-module--videoPreview--841c1";
export var videoProps = "video-module--videoProps--3a0ec";
export var videoPropsFooter = "video-module--videoPropsFooter--89051";
export var videoPropsTop = "video-module--videoPropsTop--dc1de";
export var videoStandalone = "video-module--videoStandalone--5a7bb";
export var videoStat = "video-module--videoStat--99b61";
export var videoTitle = "video-module--videoTitle--5184c";
export var youtubePlayer = "video-module--youtubePlayer--ba5d2";