import React from 'react'
import ReactDOM from 'react-dom'

import playBtn from './Play.svg'
import closeBtn from './Close.svg'
import * as styles from './video.module.less'

const getStyle = (props) => {
  const style = []
  const { width, height, fontSize, borderRadius, ...rest } = props || {}

  if (width) style.push({ width })
  if (height) style.push({ height })
  if (borderRadius) style.push({ borderRadius })

  return {
    ...style.reduce((acc, param) => ({ ...acc, ...param }), {}),
    ...rest,
  }
}

export const PlayButton = (props) => (
  <div className={styles.playButton} style={getStyle(props)}>
    <img src={playBtn} alt="" />
  </div>
)

export const VideoListAtom = (props) => (
  <div className={styles.videoList} {...props} />
)

export const VideoStandaloneAtom = ({ width, height, ...props }) => (
  <div
    className={styles.videoStandalone}
    style={{
      width: width ? `${width}px` : 'auto',
    }}
    {...props}
  />
)

export const VideoCardAtom = ({ children, onClick, ...props }) => (
  <div className={styles.videoCard} style={getStyle(props)} onClick={onClick}>
    {children}
  </div>
)

export const VideoPreviewAtom = ({ children, image, href, ...props }) => {
  const css = getStyle(props)
  return (
    <a
      className={styles.videoPreview}
      href={href}
      rel="noopener noreferrer"
      style={{
        ...css,
        background: `linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.54) 90%
      ),
      center / cover no-repeat url(${image}), rgba(0, 0, 0, 0.3)`,
      }}
    >
      {children}
    </a>
  )
}

export const VideoTitleAtom = ({ children, ...props }) => (
  <h2 className={styles.videoTitle} style={getStyle(props)}>
    {children}
  </h2>
)

export const VideoDescriptionAtom = ({ children, ...props }) => (
  <div className={styles.videoDescription} style={getStyle(props)}>
    {children}
  </div>
)

export const VideoStatAtom = ({ children, ...props }) => (
  <div className={styles.videoStat} style={getStyle(props)}>
    {children}
  </div>
)

export const VideoPropsAtom = ({ children, ...props }) => (
  <div className={styles.videoProps} style={getStyle(props)}>
    {children}
  </div>
)

export const VideoPropsTopAtom = ({ children, ...props }) => (
  <div className={styles.videoPropsTop} style={getStyle(props)}>
    {children}
  </div>
)

export const VideoPropsFooterAtom = ({ children, ...props }) => (
  <div className={styles.videoPropsFooter} style={getStyle(props)}>
    {children}
  </div>
)

export const Duration = ({ duration, ...props }) => (
  <div className={styles.duration} style={getStyle(props)}>
    {duration}
  </div>
)

export const InlineTitle = ({ children, ...props }) => (
  <div className={styles.inlineTitle} style={getStyle(props)}>
    {children}
  </div>
)

export const InlineText = ({ children, ...props }) => (
  <span className={styles.inlineText} style={getStyle(props)}>
    {children}
  </span>
)

export const Popup = (props) => {
  return ReactDOM.createPortal(
    <div data-keen-slider-pe className={styles.popup} {...props} />,
    document.body
  )
}

export const Close = (props) => (
  <img src={closeBtn} className={styles.popupClose} alt="" {...props} />
)

export const Overlay = (props) => (
  <div data-keen-slider-pe className={styles.popupOverlay} {...props} />
)

export const Player = (props) => (
  <div className={styles.popupPlayer} {...props} />
)
