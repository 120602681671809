import React from 'react'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './partner.module.less'

const HeaderLink = ({ socialUrl, iconName }) => {
  const data = useStaticQuery(query)
  return (
    <a
      href={socialUrl}
      target={'_blank'}
      referrerPolicy="no-referrer"
      className="partner__ignoreClick"
    >
      <GatsbyImage
        alt={iconName}
        image={data[iconName].childImageSharp.gatsbyImageData}
        className={cn(styles.partner__avatar, styles.partner__ignoreClick)}
      />
    </a>
  )
}

const query = graphql`
  query {
    Aleksandr: file(relativePath: { eq: "TryUs/aleksandr.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Anna: file(relativePath: { eq: "TryUs/anna.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Anastasia: file(relativePath: { eq: "TryUs/empty.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Cristina: file(relativePath: { eq: "TryUs/cristina.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Daria: file(relativePath: { eq: "TryUs/daria.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Dina: file(relativePath: { eq: "TryUs/dina.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Ivan: file(relativePath: { eq: "TryUs/ivan.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Kirill: file(relativePath: { eq: "TryUs/kirill.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Marina: file(relativePath: { eq: "TryUs/marina.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Olesya: file(relativePath: { eq: "TryUs/olesya.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Pavel: file(relativePath: { eq: "TryUs/pavel.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Sergey: file(relativePath: { eq: "TryUs/sergey.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Tatiana: file(relativePath: { eq: "TryUs/tatiana.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
    Valentina: file(relativePath: { eq: "TryUs/valentina.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 50)
      }
    }
  }
`

export default React.memo(HeaderLink)
