// extracted by mini-css-extract-plugin
export var container = "slider-module--container--8a9fe";
export var description = "slider-module--description--39b7d";
export var img = "slider-module--img--112ed";
export var list = "slider-module--list--e774e";
export var listItem = "slider-module--listItem--1978a";
export var number = "slider-module--number--c9f1a";
export var slider = "slider-module--slider--cbe47";
export var slider__header = "slider-module--slider__header--1810c";
export var slider__image = "slider-module--slider__image--75644";
export var slider__wrapper = "slider-module--slider__wrapper--f657f";
export var subscription = "slider-module--subscription--6b08f";
export var textBlock = "slider-module--textBlock--ef3cf";
export var textContainer = "slider-module--textContainer--30965";
export var title = "slider-module--title--d9867";
export var wrapper = "slider-module--wrapper--fa5ce";