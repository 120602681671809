import React, { useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import { partners } from './entity'
import ModalForPartner from './ModalForPartner'
import { useKeenSlider } from 'keen-slider/react'
import Partner from './Partner'
import { Video } from '../../components/ContestVideo'
import { useScreenSize } from '../../hooks'

import { useAnalytics } from '../../context/ga'

import * as styles from './tryUs.module.less'

export const videoRender = (
  item,
  isBigTablet,
  isTablet,
  isSmallTablet,
  isMobile
) => {
  return (
    <Video
      data={{
        attributes: {
          title: item.name,
          duration: item.duration,
          src: item.link,
          image: item.image,
        },
      }}
      options={{
        gridTemplateColumns: '1fr',
        height: `${
          isBigTablet
            ? '237px'
            : isTablet
            ? '177px'
            : isSmallTablet
            ? '217px'
            : isMobile
            ? '161px'
            : '277px'
        }`,
        width: `${
          isBigTablet
            ? '422px'
            : isTablet
            ? '316px'
            : isSmallTablet || isMobile
            ? '100%'
            : '495px'
        }`,
        maxWidth: `${isMobile && '460px'}`,
        borderRadius: '15px',
        overflow: 'hidden',
        description: false,
        stat: false,
        videoWidth: '100%',
        videoHeight: '100%',
        titleInline: true,
        textInline: true,
      }}
    />
  )
}

const firstPageFromAmount = 0

const firstPageToAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 3 : isTablet ? 2 : 5

const secondPageFromAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 4 : isTablet ? 3 : 6

const secondPageToAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 7 : isTablet ? 5 : 11

const thirdPageFromAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 8 : isTablet ? 6 : 12

const thirdPageToAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 11 : isTablet ? 8 : 17

const fourthPageFromAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 12 : isTablet ? 9 : 18

const fourthPageToAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 15 : isTablet ? 11 : 23

const fifthPageFromAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 16 : isTablet ? 12 : 24

const fifthPageToAmount = (isBigTablet, isTablet) =>
  isBigTablet ? 19 : isTablet ? 14 : 29

const AdaptiveHeight = (isMobile) => (slider) => {
  if (isMobile) {
    function updateHeight() {
      slider.container.style.maxHeight =
        slider.slides[slider.track.details.rel].offsetHeight + 'px'
    }
    slider.on('created', updateHeight)
    slider.on('slideChanged', updateHeight)
  }
}

const TryUs = () => {
  const [visible, setVisible] = useState({ isOpen: false, id: 0 })

  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isTablet = useScreenSize('tablet')
  const isBigTablet = useScreenSize('bigTablet')
  const isDesktop = useScreenSize('desktop')
  const isLargeDesktop = useScreenSize('largeDesktop')

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, slider] = useKeenSlider(
    {
      initial: 0,
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
      breakpoints: {
        '(max-width: 479px)': {},
        '(min-width: 480px) and (max-width: 767px)': {},
        '(min-width: 768px) and (max-width: 1023px)': {},
        '(min-width: 1024px) and (max-width: 1279px)': {},
        '(min-width: 1280px) and (max-width: 1439px)': {},
        '(min-width: 1440px)': {},
      },
      slidesPerView: 1,
      resetSlide: true,
      centered: true,
      mode: 'snap',
      spacing: 10,
    },
    [AdaptiveHeight(isMobile)]
  )

  const handleOpenAnalytics = useAnalytics({
    eventCategory: 'review',
    eventLabel: 'full',
  })
  const handleNextClickAnalytics = useAnalytics({
    eventCategory: 'review',
    eventLabel: 'next',
  })
  const handleModalCloseAnalytics = useAnalytics({
    eventCategory: 'review',
    eventAction: 'close',
  })

  const handleOpen = useCallback(
    (id = 0) => {
      setVisible({ isOpen: true, id })
      handleOpenAnalytics()
    },
    [handleOpenAnalytics]
  )

  const handleNextClick = useCallback(() => {
    setVisible((visible) => {
      let nextId = visible.id
      let hasNext = false
      const maxId = partners.reduce((acc, curr) => {
        return curr.id > acc ? curr.id : acc
      }, 0)
      while (!hasNext) {
        nextId += 1
        if (partners.find((el) => el.id === nextId)) {
          hasNext = true
        }
        if (nextId > maxId) break
      }
      if (!hasNext) return

      return { isOpen: true, id: nextId }
    })
    handleNextClickAnalytics()
  }, [handleNextClickAnalytics])

  const handleClose = useCallback(() => {
    handleModalCloseAnalytics()

    setVisible({ isOpen: false, id: 0 })
  }, [handleModalCloseAnalytics])

  useEffect(() => {
    if (visible.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [visible.isOpen])

  const ArrowLeft = (props) => {
    const disabled = props.disabled ? styles.arrowDisabled : ''
    return (
      <svg
        onClick={props.onClick}
        id={'arrowSlideLeft'}
        className={cn(styles.arrow, styles.arrowLeft, disabled)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    )
  }

  const ArrowRight = (props) => {
    const disabled = props.disabled ? styles.arrowDisabled : ''
    return (
      <svg
        onClick={props.onClick}
        id={'arrowSlideRight'}
        className={cn(styles.arrow, styles.arrowRight, disabled)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    )
  }

  const partnerRender = (item) => (
    <Partner
      key={item.id}
      data={item}
      onClick={() => {
        return handleOpen(item.id)
      }}
      isBigTablet={isBigTablet}
    />
  )

  const generalCardRender = (idArray, from, to) =>
    idArray.map(
      (item) =>
        item.id >= from &&
        item.id <= to && (
          <li className={styles.personItem} key={item.id}>
            {item.type === 'person'
              ? partnerRender(item)
              : videoRender(
                  item,
                  isBigTablet,
                  isTablet,
                  isSmallTablet,
                  isMobile
                )}
          </li>
        )
    )

  const customCardRender = (idArray) =>
    idArray &&
    idArray.map((item) => (
      <li className={styles.personItem} key={item.id}>
        {item.type === 'person'
          ? partnerRender(item)
          : videoRender(item, isBigTablet, isTablet, isSmallTablet, isMobile)}
      </li>
    ))

  const generateFullCard = (idArray) => (
    <div className="keen-slider__slide">
      <ul className={styles.personList}>
        {idArray &&
          idArray.map((item) => (
            <li className={styles.personItem} key={item.id}>
              {item.type === 'person'
                ? partnerRender(item)
                : videoRender(
                    item,
                    isBigTablet,
                    isTablet,
                    isSmallTablet,
                    isMobile
                  )}
            </li>
          ))}
      </ul>
    </div>
  )

  return (
    <section
      id={'tryUs'}
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr'
      )}
    >
      <h2 className={cn(styles.title, 'text-center')}>Уже с&nbsp;нами</h2>

      <div className={styles.navigationWrapper}>
        <div ref={sliderRef} className={cn('keen-slider', styles.tryUsSlider)}>
          <div className="keen-slider__slide">
            <ul className={styles.personList}>
              {isMobile
                ? customCardRender([partners[0]])
                : isSmallTablet
                ? customCardRender([partners[0], partners[1]])
                : generalCardRender(
                    partners,
                    firstPageFromAmount,
                    firstPageToAmount(isBigTablet, isTablet)
                  )}
            </ul>
          </div>

          <div className="keen-slider__slide">
            <ul className={styles.personList}>
              {isMobile
                ? customCardRender([partners[1]])
                : isSmallTablet
                ? customCardRender([partners[2], partners[3]])
                : generalCardRender(
                    partners,
                    secondPageFromAmount(isBigTablet, isTablet),
                    secondPageToAmount(isBigTablet, isTablet)
                  )}
            </ul>
          </div>

          {!isDesktop && !isLargeDesktop && !isBigTablet && (
            <div className="keen-slider__slide">
              <ul className={styles.personList}>
                {isMobile
                  ? customCardRender([partners[3], partners[4]])
                  : isSmallTablet
                  ? customCardRender([partners[9]])
                  : isTablet
                  ? customCardRender([partners[12], partners[14], partners[13]])
                  : generalCardRender(
                      partners,
                      fifthPageFromAmount(isBigTablet, isTablet),
                      fifthPageToAmount(isBigTablet, isTablet)
                    )}
              </ul>
            </div>
          )}

          {!isDesktop && !isLargeDesktop && (
            <div className="keen-slider__slide">
              <ul className={styles.personList}>
                {isMobile
                  ? customCardRender([partners[10]])
                  : isSmallTablet
                  ? customCardRender([partners[6], partners[7]])
                  : generalCardRender(
                      partners,
                      fourthPageFromAmount(isBigTablet, isTablet),
                      fourthPageToAmount(isBigTablet, isTablet)
                    )}
              </ul>
            </div>
          )}

          {!isMobile && (
            <div className="keen-slider__slide">
              <ul className={styles.personList}>
                {isSmallTablet
                  ? customCardRender([partners[4], partners[5]])
                  : generalCardRender(
                      partners,
                      thirdPageFromAmount(isBigTablet, isTablet),
                      thirdPageToAmount(isBigTablet, isTablet)
                    )}
              </ul>
            </div>
          )}

          {!isDesktop && !isLargeDesktop && !isBigTablet && !isTablet && (
            <div className="keen-slider__slide">
              <ul className={styles.personList}>
                {isMobile && customCardRender([partners[5]])}
                {isSmallTablet &&
                  customCardRender([partners[10], partners[11]])}
              </ul>
            </div>
          )}

          {isMobile && generateFullCard([partners[2], partners[6]])}
          {isMobile && generateFullCard([partners[7]])}
          {isMobile && generateFullCard([partners[11], partners[9]])}
          {isMobile && generateFullCard([partners[12]])}
          {isMobile && generateFullCard([partners[13]])}
          {isMobile && generateFullCard([partners[14]])}

          {isSmallTablet && generateFullCard([partners[12], partners[13]])}
          {isSmallTablet && generateFullCard([partners[14]])}
        </div>

        {loaded && slider.current && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.current?.prev()}
              disabled={currentSlide === 0}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.current?.next()}
              disabled={
                currentSlide === slider.current?.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>

      {loaded && slider.current && (
        <div className={styles.tryUsDots} id="tryUsDots">
          {[...Array(slider.current.track.details.slides.length).keys()].map(
            (idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    slider.current?.moveToIdx(idx)
                  }}
                  className={cn(
                    styles.tryUsDot,
                    currentSlide === idx && styles.tryUsDotActive
                  )}
                />
              )
            }
          )}
        </div>
      )}

      <ModalForPartner
        isOpen={visible.isOpen}
        partner={partners.find((el) => el.id === visible.id)}
        onClose={handleClose}
        onClickNext={handleNextClick}
      />
    </section>
  )
}

export default TryUs
