// extracted by mini-css-extract-plugin
export var card = "digitalMortgage-module--card--0b1c6";
export var cardBack = "digitalMortgage-module--card--back--72da3";
export var cardFifth = "digitalMortgage-module--card--fifth--4551c";
export var cardFirst = "digitalMortgage-module--card--first--5ee03";
export var cardFourth = "digitalMortgage-module--card--fourth--9db4b";
export var cardSecond = "digitalMortgage-module--card--second--7d826";
export var cardSeven = "digitalMortgage-module--card--seven--3d5ec";
export var cardThird = "digitalMortgage-module--card--third--d86d5";
export var card__close = "digitalMortgage-module--card__close--2590d";
export var container = "digitalMortgage-module--container--6d8ad";
export var item = "digitalMortgage-module--item--05561";
export var itemFifth = "digitalMortgage-module--item--fifth--11132";
export var itemFourth = "digitalMortgage-module--item--fourth--0356f";
export var itemSecond = "digitalMortgage-module--item--second--c776c";
export var itemSeven = "digitalMortgage-module--item--seven--43048";
export var itemSixth = "digitalMortgage-module--item--sixth--d6438";
export var itemThird = "digitalMortgage-module--item--third--da4f9";
export var item__back = "digitalMortgage-module--item__back--8d5a9";
export var item__backActive = "digitalMortgage-module--item__back--active--39801";
export var item__front = "digitalMortgage-module--item__front--2ac13";
export var item__frontActive = "digitalMortgage-module--item__front--active--5d4e9";
export var item__header = "digitalMortgage-module--item__header--9c06e";
export var item__headerBack = "digitalMortgage-module--item__header--back--2d0b8";
export var item__headerFifth = "digitalMortgage-module--item__header--fifth--a4dd1";
export var item__headerFirst = "digitalMortgage-module--item__header--first--19672";
export var item__headerFourth = "digitalMortgage-module--item__header--fourth--453a9";
export var item__headerSecond = "digitalMortgage-module--item__header--second--464eb";
export var item__headerSixth = "digitalMortgage-module--item__header--sixth--992fa";
export var item__headerThird = "digitalMortgage-module--item__header--third--f8d3d";
export var item__image = "digitalMortgage-module--item__image--2e877";
export var item__imageFifth = "digitalMortgage-module--item__image--fifth--d687f";
export var item__imageFirst = "digitalMortgage-module--item__image--first--8afed";
export var item__imageFourth = "digitalMortgage-module--item__image--fourth--6114d";
export var item__imageSecond = "digitalMortgage-module--item__image--second--06ab9";
export var item__imageSeven = "digitalMortgage-module--item__image--seven--3529b";
export var item__imageSixth = "digitalMortgage-module--item__image--sixth--c3999";
export var item__imageThird = "digitalMortgage-module--item__image--third--00944";
export var item__more = "digitalMortgage-module--item__more--a1e59";
export var item__textBack = "digitalMortgage-module--item__text--back--e76ff";
export var list = "digitalMortgage-module--list--749b1";
export var list__wrapper = "digitalMortgage-module--list__wrapper--f0f55";