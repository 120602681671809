// extracted by mini-css-extract-plugin
export var animation = "dealSteps-module--animation--a9682";
export var container = "dealSteps-module--container--ec804";
export var sidebar = "dealSteps-module--sidebar--aafd0";
export var step = "dealSteps-module--step--d0d93";
export var stepActive = "dealSteps-module--step--active--c465f";
export var step__description = "dealSteps-module--step__description--b79b4";
export var step__descriptionActive = "dealSteps-module--step__description--active--1d36e";
export var step__order = "dealSteps-module--step__order--cc8eb";
export var step__text = "dealSteps-module--step__text--c15fd";
export var step__title = "dealSteps-module--step__title--4cd01";
export var step__titleActive = "dealSteps-module--step__title--active--a9662";
export var stepsBody = "dealSteps-module--steps-body--c3c57";
export var subTitle = "dealSteps-module--subTitle--3f318";
export var title = "dealSteps-module--title--f6a10";
export var wrapper = "dealSteps-module--wrapper--77985";