import React, { useState } from 'react'
import cn from 'classnames'
import * as styles from './whatToDo.module.less'

const Arrow = ({ modifier }) => {
  return (
    <div
      className={cn(styles.partnerArrow__wrapper, {
        [styles[`partnerArrow__wrapper${modifier}`]]: modifier,
      })}
    >
      <div className={styles.partnerArrow} />
      <div className={cn(styles.partnerArrow, styles.partnerArrowUnder)} />
    </div>
  )
}

const Description = () => {
  return (
    <div className={styles.descriptionWrapper}>
      <div className={styles.descriptionBlock}>
        <div className={styles.descriptionNumber}>1</div>
        <div className={styles.descriptionText}>
          Вы&nbsp;подключаетесь к&nbsp;нашей цифровой платформе и&nbsp;получаете
          личного куратора
        </div>
      </div>
      <div className={styles.descriptionBlock}>
        <div className={styles.descriptionNumber}>2</div>
        <div className={styles.descriptionText}>
          Платформа напрямую подключена к ведущим банкам, застройщикам,
          оценочным и страховым компаниям
        </div>
      </div>
      <div className={styles.descriptionBlock}>
        <div className={styles.descriptionNumber}>3</div>
        <div className={styles.descriptionText}>
          Ищите клиентов, помогайте им&nbsp;получать ипотеку и&nbsp;приобретать
          недвижимость через платформу и&nbsp;зарабатывайте агентское
          вознаграждение
        </div>
      </div>
    </div>
  )
}

const DescriptionMobile = () => {
  return (
    <div className={styles.descriptionMobileText}>
      Платформа напрямую подключена к&nbsp;ведущим банкам, застройщикам,
      оценочным и&nbsp;страховым компаниям. Помогайте своим клиентам приобрести
      недвижимость через платформу и&nbsp;зарабатывайте агентское
      вознаграждение.
    </div>
  )
}

export default () => {
  const [animation, setAnimation] = useState(3)

  return (
    <section
      id={'whatToDo'}
      className={cn(styles.section, 'container-padding--vr')}
    >
      <div className={styles.mainCenter}>
        <h2 className={styles.title}>Как всё устроено</h2>
        <DescriptionMobile />
        <div className={styles.mainWrapper}>
          <div className={styles.blockWrapper}>
            <div
              className={cn(
                styles.animationBlock,
                styles.animationBlockClient,
                styles.thirdBlock
              )}
            >
              Клиенты
            </div>
            <Arrow modifier={'Sixth'} />
          </div>
          <div className={styles.blockWrapper}>
            <div className={cn(styles.animationBlock, styles.partner)}>
              <span>Вы</span>
            </div>
            <Arrow modifier={'Fifth'} />
          </div>
          <div className={styles.blockWrapper}>
            <div className={cn(styles.animationBlock, styles.partnerPlatform)}>
              <div className={styles.logo} />
              <span>Платформа Ипотека.Центр</span>
            </div>
          </div>
          <div className={styles.lastBlockWrapper}>
            <Arrow modifier={'Second'} />
            <Arrow modifier={'Third'} />
            <Arrow modifier={'Fourth'} />
            <Arrow modifier={'Seventh'} />

            <div
              className={cn(
                styles.animationBlock,
                styles.animationBlockCompany,
                styles.secondBlock__company,
                styles.secondBlock__companyBanks
              )}
            >
              <span>Банки</span>
            </div>

            <div
              className={cn(
                styles.animationBlock,
                styles.animationBlockCompany,
                styles.secondBlock__company,
                styles.secondBlock__companyDeveloper
              )}
            >
              <span>Застройщики</span>
            </div>

            <div
              className={cn(
                styles.animationBlock,
                styles.animationBlockCompany,
                styles.secondBlock__company,
                styles.secondBlock__companyInsurance
              )}
            >
              <span>Страховые компании</span>
            </div>

            <div
              className={cn(
                styles.animationBlock,
                styles.animationBlockCompany,
                styles.secondBlock__company,
                styles.secondBlock__companyEvaluative
              )}
            >
              <span>Оценочные компании</span>
            </div>
          </div>
        </div>
        <Description animation={animation} />
      </div>
    </section>
  )
}
