import React, { useState } from 'react'
import cn from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import { useScreenSize } from '../../hooks'
import { NewsList } from './configNews'
import ArrowIcon from './img/arrow.inline.svg'
import * as styles from './news.module.less'

export default () => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = useState(false)
  const multipleRows = useScreenSize('largeDesktop')

  const [sliderRef, slider] = useKeenSlider(
    {
      breakpoints: {
        '(max-width: 479px)': {
          slides: {
            perView: 1,
            spacing: 20,
          },
        },
        '(min-width: 480px) and (max-width: 1439px)': {
          slides: {
            perView: 'auto',
            spacing: 40,
          },
        },
        '(min-width: 1440px)': {
          slides: {
            perView: 1,
            spacing: 40,
          },
        },
      },
      initial: 0,
      mode: 'free-snap',
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel)
      },
      created() {
        setLoaded(true)
      },
    },
    []
  )

  return (
    <section
      id="news"
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr'
      )}
    >
      <h2 className={cn(styles.title, 'text-center')}>СМИ о&nbsp;нас</h2>
      <div className={styles.body}>
        <div className={styles.navigationWrapper}>
          <div ref={sliderRef} className={cn('keen-slider', styles.newsSlider)}>
            {slidesRender(multipleRows)}
          </div>
          {loaded && slider.current && (
            <>
              <Arrow
                onClick={(e) => e.stopPropagation() || slider.current?.prev()}
                disabled={currentSlide === 0}
                side={'Left'}
              />
              <Arrow
                onClick={(e) => e.stopPropagation() || slider.current?.next()}
                disabled={
                  currentSlide ===
                  slider.current?.track.details.slides.length - 1
                }
                side={'Right'}
              />
            </>
          )}
        </div>
      </div>
      {loaded && slider.current && (
        <div className={styles.dots}>
          {[...Array(slider.current.track.details.slides.length).keys()].map(
            (idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    slider.current?.moveToIdx(idx)
                  }}
                  className={cn(styles.dot, {
                    [styles.active]: currentSlide === idx,
                  })}
                />
              )
            }
          )}
        </div>
      )}
    </section>
  )
}

const Arrow = (props) => {
  const disabled = props.disabled ? styles.arrowDisabled : ''
  return (
    <ArrowIcon
      onClick={props.onClick}
      className={cn(styles.arrow, styles[`arrow${props.side}`], disabled)}
    />
  )
}

const generateSlide = (articles, index) => {
  return (
    <div className={cn(styles.slide, 'keen-slider__slide')} key={index}>
      {articles.map((article) => (
        <div className={styles.articleBlock} key={`${article.link}`}>
          <a
            href={article.link}
            noreferrer="true"
            noopener="true"
            target="_blank"
          >
            <img
              src={article.img}
              alt={article.alt}
              className={cn(styles.articleBlock_img, styles[article.alt])}
              loading="lazy"
            />
            <p className={styles.articleText}>{article.text}</p>
          </a>
          {article.otherSmi}
        </div>
      ))}
    </div>
  )
}

const slidesRender = (multipleRows) => {
  const amountOfCard = multipleRows ? 6 : 1
  const amountOfSlides = Math.round(NewsList.length / amountOfCard)
  let from = 0
  let to = amountOfCard
  const slides = []

  for (let k = 0; k < amountOfSlides; k++) {
    const partOfArticles = NewsList.slice(from, to)
    slides.push(generateSlide(partOfArticles, k))
    from = to
    to =
      NewsList.length - from < amountOfCard
        ? NewsList.length
        : (to += amountOfCard)
  }

  return <>{slides}</>
}
