import React, { useCallback, useState } from 'react'
import { useModal, useScreenSize } from '../../hooks'
import { VideoPopup } from './VideoPopup'
import { YoutubePlayer } from '../YoutubePlayer'

import {
  VideoCardAtom,
  VideoPreviewAtom,
  PlayButton,
  VideoTitleAtom,
  VideoPropsAtom,
  VideoDescriptionAtom,
  VideoStatAtom,
  VideoPropsTopAtom,
  VideoPropsFooterAtom,
  Duration,
  InlineTitle,
} from './atoms'

const VideoPreview = ({
  id,
  loading,
  height,
  duration,
  title,
  src: link,
  children,
  onClick,
  text,
  ...props
}) => {
  return (
    <VideoPreviewAtom
      loading={loading}
      height={height}
      onClick={onClick}
      href={link}
      {...props}
    >
      <PlayButton
        width={props.playBtnWidth || '48px'}
        height={props.playBtnHeight || '48px'}
      />
      <Duration duration={duration} />
      <InlineTitle>
        {title}
        <span>&nbsp;{text}</span>
      </InlineTitle>

      {children}
    </VideoPreviewAtom>
  )
}

export const Video = ({
  data = {},
  inline,
  loading,
  options: styleProps = {},
  hidden,
  onPlay,
  onPopupClose,
  className,
}) => {
  const { opened, open, close } = useModal({ defaultOpen: false })
  const [playing, setPlaying] = useState(false)

  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isTablet = useScreenSize('tablet')
  const isBigTablet = useScreenSize('bigTablet')

  const play = useCallback(
    (e) => {
      e.preventDefault()
      onPlay && onPlay()
      setPlaying(true)
    },
    [onPlay]
  )

  const openPopup = useCallback(
    (e) => {
      e.preventDefault()
      open()
      if (document.getElementById('arrowSlideLeft')) {
        document.getElementById('arrowSlideLeft').style.pointerEvents = 'none'
        document.getElementById('arrowSlideRight').style.pointerEvents = 'none'
        document.getElementById('tryUsDots').style.pointerEvents = 'none'
      }
    },
    [open]
  )

  const onClose = useCallback(() => {
    close()
    onPopupClose && onPopupClose()
    if (document.getElementById('arrowSlideLeft')) {
      document.getElementById('arrowSlideLeft').style.pointerEvents = 'all'
      document.getElementById('arrowSlideRight').style.pointerEvents = 'all'
      document.getElementById('tryUsDots').style.pointerEvents = 'all'
    }
  }, [close, onPopupClose])

  const isVideoPropsAtomNeeded =
    styleProps.description || styleProps.stat || styleProps.title

  if (!loading && !data) return null

  if (loading) return <div>Loading</div>

  if (!data.attributes) return null

  const title = data.attributes.title
  const description = data.attributes.description

  return (
    <>
      <VideoCardAtom
        gridTemplateColumns={styleProps.gridTemplateColumns || 'auto'}
        gridTemplateRows={styleProps.gridTemplateRows || 'auto'}
        height={styleProps.height}
        border={styleProps.border}
        borderRadius={styleProps.borderRadius}
        overflow={styleProps.overflow}
        width={styleProps.width}
        fontSize={styleProps.fontSize}
        onClick={inline ? play : openPopup}
        hide={hidden}
        className={className}
      >
        {playing ? (
          <YoutubePlayer
            data={data}
            width={styleProps.videoWidth || '100%'}
            height={styleProps.videoHeight || '100%'}
          />
        ) : (
          <VideoPreview
            id={data.id}
            image={data.attributes.image}
            duration={data.attributes.duration}
            title={styleProps.titleInline && data.attributes.title}
            text={styleProps.textInline && data.attributes.text}
            src={data.attributes.src}
            onClick={inline ? play : openPopup}
            borderRadius={styleProps.borderRadius || ''}
            overflow={styleProps.overflow || ''}
            margin={styleProps.margin || ''}
            style={{ zIndex: styleProps.zIndex || 'auto' }}
            playBtnWidth={
              styleProps.playBtnWidth || isSmallTablet
                ? '48px'
                : isMobile
                ? '36px'
                : isBigTablet || isTablet
                ? '64px'
                : '120px'
            }
            playBtnHeight={
              styleProps.playBtnHeight || isSmallTablet
                ? '48px'
                : isMobile
                ? '36px'
                : isBigTablet || isTablet
                ? '64px'
                : '120px'
            }
            aspectRatio={styleProps.aspectRatio}
          />
        )}
        {isVideoPropsAtomNeeded && (
          <VideoPropsAtom>
            <VideoPropsTopAtom padding={styleProps.paddingPropsTop}>
              {styleProps.headline && data.attributes.title && (
                <VideoTitleAtom
                  size={styleProps.headlineSize}
                  maxHeight={styleProps.headlineMaxHeight}
                  padding={styleProps.headlinePadding}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {styleProps.description && data.attributes.description && (
                <VideoDescriptionAtom
                  maxHeight={styleProps.descriptionMaxHeight}
                  padding={styleProps.descriptionPadding}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </VideoPropsTopAtom>
            {styleProps.stat && (
              <VideoPropsFooterAtom>
                <VideoStatAtom>{data.postedAt}</VideoStatAtom>
              </VideoPropsFooterAtom>
            )}
          </VideoPropsAtom>
        )}
      </VideoCardAtom>
      <VideoPopup
        data={data}
        opened={opened}
        onClose={onClose}
        onPlay={onPlay}
      />
    </>
  )
}
