import React, { useState, useMemo, useCallback, useRef } from 'react'
import Slider from 'react-slick'
import cn from 'classnames'

import * as styles from './slider.module.less'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'

const renderSlider = (list = []) => {
  const data = useStaticQuery(query)

  return list.map(({ slide, title, description }, i) => (
    <div key={i} className={styles.slider}>
      <div className={styles.slider__header} />

      <div className={styles.slider__image}>
        <GatsbyImage
          image={data[slide].childImageSharp.gatsbyImageData}
          formats={['auto', 'webp', 'avif']}
          alt={'слайд'}
          loading="lazy"
        />
      </div>

      <div className={cn('container-padding--hr', styles.textBlock)}>
        <ul className={styles.list}>{renderItem({ title, description }, i)}</ul>
      </div>
    </div>
  ))
}

const renderItem = ({ title, description }, i) => {
  const index = i + 1

  return (
    <li className={cn(styles.listItem)}>
      <span className={styles.number}>{index}</span>

      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>

        <div className={styles.description}>{description}</div>
      </div>
    </li>
  )
}

const MobileSlider = ({ list }) => {
  const ref = useRef()
  const [activeSlide, setActiveSlide] = useState(1)
  const onBeforeChange = useCallback(
    (current, next) => setActiveSlide(next + 1),
    []
  )

  const settings = useMemo(
    () => ({
      arrows: false,
      dots: true,
      infinite: false,
      beforeChange: onBeforeChange,
    }),
    []
  )

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Slider
            ref={ref}
            {...settings}
            className={cn(styles.slider__wrapper, 'deal-step-mobile-slider')}
          >
            {renderSlider(list)}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default MobileSlider

const query = graphql`
  query {
    first: file(relativePath: { eq: "DealSteps/slide1Mobile.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
    second: file(relativePath: { eq: "DealSteps/slide2Mobile.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
    third: file(relativePath: { eq: "DealSteps/slide3Mobile.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
    fourth: file(relativePath: { eq: "DealSteps/slide4Mobile.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
    fifth: file(relativePath: { eq: "DealSteps/slide5Mobile.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
