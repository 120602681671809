import rbk from './img/rbk.svg'
import mainfin from './img/mainfin.svg'
import skolkovo from './img/sk.png'
import ipoteka from './img/ipoteka.svg'
import kommersant from './img/kommersant.svg'
import bankiru from './img/bankiru.svg'
import bankiros from './img/bankiRos.svg'

import React from 'react'
import { OtherSmi } from './otherSmi'

export const NewsList = [
  {
    img: ipoteka,
    alt: 'ipoteka',
    text: (
      <>
        Ипотека.Центр совместно с&nbsp;Совкомбанком запускает акцию
        для&nbsp;профессионалов рынка ипотеки и&nbsp;недвижимости «Выгодные
        сделки».
      </>
    ),
    link: '/sovcombank-contest',
  },
  {
    img: bankiros,
    alt: 'bankiros',
    text: (
      <>
        Ипотека.Центр объявила финалистов конкурса &laquo;Лучший эксперт в
        ипотеке и&nbsp;недвижимости России&raquo;. По&nbsp;итогам 2022 года
        в&nbsp;конкурсе приняли участие более 25&nbsp;000 специалистов рынка
        недвижимости.
      </>
    ),
    link: 'https://bankiros.ru/news/lucsij-ekspert-v-ipoteke-i-nedvizimosti-rossii-10495',
  },
  {
    img: kommersant,
    alt: 'kommersant',
    text: (
      <>
        По&nbsp;итогам прошлого года доля ипотечных сделок в&nbsp;структурах
        продаж девелоперов Петербурга достигла 75%. По&nbsp;объему выдачи
        льготных ипотечных кредитов Петербург оказался лидером в&nbsp;России.
      </>
    ),
    link: 'https://www.kommersant.ru/doc/4703182',
  },
  {
    img: bankiru,
    alt: 'bankiru',
    text: (
      <>
        Компания &laquo;Ипотека.Центр&raquo; запустила ежегодный конкурс
        &laquo;Лучший ипотечный эксперт России&raquo;. Первая
        и&nbsp;единственная премия для профессионалов рынка недвижимости.
      </>
    ),
    link: 'https://www.banki.ru/news/lenta/?id=10938272',
  },
  {
    img: ipoteka,
    alt: 'ipoteka',
    text: (
      <>
        В&nbsp;конце февраля состоялось награждение финалистов премии
        &laquo;Лучший ипотечный эксперт России 2020&raquo;. Это единственный
        ежегодный всероссийский конкурс для профессионалов ипотечного бизнеса.
      </>
    ),
    link: '/best-ipoteka-expert',
  },
  {
    img: ipoteka,
    alt: 'ipoteka',
    text: (
      <>
        Ипотека.Центр усиливает свои позиции на&nbsp;рынке ипотечного
        кредитования. Новый офис компании открылся в&nbsp;Новосибирске.
        Расширение городов присутствия связано с&nbsp;высоким спросом
        на&nbsp;сервис.
      </>
    ),
    link: '/novosibirsk-market',
  },
  {
    img: ipoteka,
    alt: 'ipoteka',
    text: (
      <>
        В&nbsp;начале июля компания Ипотека.Центр отметила три года.
        С&nbsp;момента основания стартап запустил собственную платформу
        по&nbsp;цифровизации ипотечного бизнеса, создал новую профессию.
      </>
    ),
    link: '/three-years',
  },
  {
    img: skolkovo,
    alt: 'skolkovo',
    text: (
      <>
        Платформа Ипотека.Центр&nbsp;&mdash; собственная российская разработка
        ООО &laquo;ФинМаркет&raquo; получила статус резидента
        &laquo;Сколково&raquo;.
      </>
    ),
    link: '/skolkovo',
  },
  {
    img: rbk,
    alt: 'rbk',
    text: (
      <>
        В&nbsp;Москве состоялась закрытая церемония награждения лучших
        менеджеров ипотеки в&nbsp;рамках премии &laquo;Лучший ипотечный эксперт
        России&raquo;. Премия учреждена в&nbsp;2019 году компанией
        Ипотека.Центр.
      </>
    ),
    link: 'https://plus.rbc.ru/pressrelease/5e3ad88e7a8aa9af45f95652',
    otherSmi: (
      <>
        <OtherSmi />
      </>
    ),
  },
  {
    img: mainfin,
    alt: 'mainfin',
    text: (
      <>
        Банк ТКБ объявляет о&nbsp;начале сотрудничества
        с&nbsp;&laquo;Ипотека.Центр&raquo;. В&nbsp;рамках партнерства клиентам
        и&nbsp;экспертам сервиса будет доступна полная линейка ипотечных
        продуктов банка.
      </>
    ),
    link: 'https://mainfin.ru/press-relizy/ipoteka-ot-tkb-stala-dostupna-na-platforme-ipotekacentr',
  },
]
