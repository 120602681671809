import React, { useState } from 'react'

import cookie from './Image/cookie.png'
import close from './Image/close.svg'
import { PRIVACY_POLICY_LINK } from '../../constants'

import * as styles from './privacyBanner.module.less'

const previouslyShown =
  typeof window !== 'undefined' && localStorage.getItem('privacyBanner')

const PrivacyBanner = ({ setIsShowPopup }) => {
  const [isShow, setIsShow] = useState(true)

  const closePrivacyBanner = () => {
    typeof window !== 'undefined' && localStorage.setItem('privacyBanner', true)
    setIsShow(false)
    setIsShowPopup(true)
  }

  return (
    typeof window !== 'undefined' &&
    previouslyShown === null &&
    isShow && (
      <div className={styles.container}>
        <img src={cookie} className={styles.cookie} loading="lazy" />
        <p className={styles.privacyText}>
          Мы&nbsp;используем cookies для быстрой работы сайта. Оставаясь тут,
          вы&nbsp;принимаете&nbsp;
        </p>
        <p className={styles.privacyTextSmall}>
          Оставаясь на сайте, вы&nbsp;принимаете&nbsp;
          <a
            href={PRIVACY_POLICY_LINK}
            rel="noreferrer noopener"
            target="_blank"
            className={styles.privacyLinkSmall}
          >
            условия обработки персональных данных
          </a>
        </p>
        <a
          href={PRIVACY_POLICY_LINK}
          rel="noreferrer noopener"
          target="_blank"
          className={styles.privacyLink}
        >
          условия обработки персональных данных
        </a>
        <img
          src={close}
          className={styles.close}
          onClick={closePrivacyBanner}
          loading="lazy"
        />
      </div>
    )
  )
}
export default PrivacyBanner
