import React from 'react'

const questionItems = [
  {
    title: <span>Кто может получить доступ к&nbsp;платформе?</span>,
    analyticsText: 'Кто может получить доступ к платформе?',
    description: (
      <span>
        Доступ и&nbsp;возможность заработать на&nbsp;сделках могут получить
        брокеры, риэлторы и&nbsp;другие эксперты рынка ипотеки
        и&nbsp;недвижимости.
      </span>
    ),
  },
  {
    title: <span>Как заработать на&nbsp;новостройках?</span>,
    analyticsText: 'Как заработать на новостройках?',
    description: (
      <span>
        На&nbsp;нашей платформе есть{' '}
        <a href={'https://partner.ipoteka.center/newflats'} target={'_blank'}>
          сервис для подбора жилья.
        </a>{' '}
        Он&nbsp;поможет заработать на&nbsp;продаже новостроек от&nbsp;наших
        партнеров застройщиков.
        <br />
        <br />
        Заработок будет больше, если проводить ипотечные сделки
        с&nbsp;новостройками, представленными на&nbsp;платформе.
      </span>
    ),
  },
  {
    title: <span>Что требуется, чтобы получить доступ?</span>,
    analyticsText: 'Что требуется, чтобы получить доступ?',
    description: (
      <span>
        Чтобы получить доступ, достаточно{' '}
        <span
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            color: '#4a90e2',
          }}
          onClick={() =>
            document.getElementById('footerRegisterButton').click()
          }
          rel="noopener noreferrer"
        >
          зарегистрироваться
        </span>
        , указав контактные данные.
      </span>
    ),
  },
  {
    title: (
      <span>Продаю вторичку. Как переквалифицироваться на&nbsp;первичку?</span>
    ),
    analyticsText: 'Продаю вторичку. Как переквалифицироваться на первичку?',
    description: (
      <span>
        Посещайте наши вебинары и&nbsp;мастер-классы, посвящённые заработку
        на&nbsp;новостройках. Регулярно используйте раздел
        &laquo;Новостройки&raquo; на&nbsp;платформе
        &laquo;Ипотека.Центр&raquo;&nbsp;&mdash; в&nbsp;нём много подсказок.
        Кроме того, за&nbsp;каждым пользователем закрепляется персональный
        куратор, который поможет разобраться во&nbsp;всех тонкостях.
      </span>
    ),
  },
  {
    title: <span>Сколько это стоит?</span>,
    analyticsText: 'Сколько это стоит?',
    description: (
      <span>
        Платформа &laquo;Ипотека.Центр&raquo; бесплатна. Также вам будут
        доступны обучающие программы, вебинары и&nbsp;мастер-классы, посвященные
        заработку на&nbsp;ипотеке. Кроме&nbsp;того, мы&nbsp;проводим закрытые
        профессиональные мероприятия для участников нашей партнёрской программы,
        куда обязательно вас пригласим.
      </span>
    ),
  },
  {
    title: (
      <span>Работа на&nbsp;платформе проще, чем напрямую с&nbsp;банком?</span>
    ),
    analyticsText: 'Работа на платформе проще, чем напрямую с банком?',
    description: (
      <span>
        Да, ведь она создана, чтобы упрощать работу ипотечного специалиста.
        Наш&nbsp;сервис позволит вам:
        <br />
        <br />
        &bull; получать всегда актуальные предложения банков в&nbsp;едином
        smart-калькуляторе; <br />
        <br />
        &bull; оперативно отправлять заявки;
        <br />
        <br />
        &bull; автоматизировать ручной труд с&nbsp;помощью нашего процессинга
        и&nbsp;распознавания документов; <br />
        <br />
        &bull; использовать единую анкету для всех банков; <br />
        <br />
        &bull; быть всегда в курсе статуса заявки;
        <br />
        <br />
        &bull; хранить всю историю коммуникации с&nbsp;клиентом.
        <br />
        <br />
        Вы&nbsp;не&nbsp;только упростите свою работу, но&nbsp;и&nbsp;получаете
        за&nbsp;каждую заявку вознаграждение.
      </span>
    ),
  },
  {
    title: <span>Какие ставки по&nbsp;ипотеке вы предлагаете?</span>,
    analyticsText: 'Какие ставки по ипотеке вы предлагаете?',
    description: (
      <span>
        Ставки на&nbsp;платформе соответствуют банковским программам.
        В&nbsp;некоторых случаях наши партнёрские отношения с&nbsp;банками
        позволяют предлагать клиентам ставку ниже общедоступных.
      </span>
    ),
  },
  {
    title: <span>Как я&nbsp;буду получать заработанные деньги?</span>,
    analyticsText: 'Как я буду получать заработанные деньги?',
    description: (
      <span>
        Ипотека.Центр позволяет получать доход от ипотечных сделок на&nbsp;карту
        или по&nbsp;реквизитам счёта. Вы&nbsp;можете работать с&nbsp;нами как
        самозанятый, ИП или юридическое лицо. Вы&nbsp;можете выводить как всю
        заработанную вами сумму, так и&nbsp;часть, в&nbsp;любое удобное время.
      </span>
    ),
  },
  {
    title: <span>Что нужно сделать, чтобы заработать на платформе?</span>,
    analyticsText: 'Что нужно сделать, чтобы заработать на платформе?',
    description: (
      <span>
        Нужно подобрать для клиента оптимальный банк и&nbsp;организовать сделку.
        То&nbsp;есть пройти по&nbsp;привычному процессу, но&nbsp;на&nbsp;нашей
        платформе.
        <br />
        <br />
        Плюс вы&nbsp;можете дополнительно заработать на&nbsp;реализации
        новостроек от&nbsp;наших партнеров застройщиков.
        <br />
        <br />
        Вы&nbsp;сможете пройти все этапы в&nbsp;одном окне, не&nbsp;выходя
        из&nbsp;дома, офиса, или любой другой удобной локации, где
        вы&nbsp;предпочитаете работать.
      </span>
    ),
  },
  {
    title: <span>Нужно&nbsp;ли заключать с&nbsp;вами трудовой договор?</span>,
    analyticsText: 'Нужно ли заключать с вами трудовой договор?',
    description: (
      <span>
        Вам не&nbsp;придётся заключать с&nbsp;нами трудовой договор, чтобы
        заработать на&nbsp;ипотеке. Достаточно принять условия пользовательского
        соглашения в&nbsp;нашем сервисе.
      </span>
    ),
  },
  {
    title: <span>А&nbsp;если у&nbsp;меня совсем мало опыта?</span>,
    analyticsText: 'А если у меня совсем мало опыта?',
    description: (
      <span>
        Оставляйте заявку! Мы&nbsp;подскажем альтернативные варианты, как
        заработать и&nbsp;получить недостающий опыт.
      </span>
    ),
  },
]

export const questions = questionItems.map((el, i) => ({
  ...el,
  id: i,
  isSent: false,
}))
