import React from 'react'
import bank from './img/bank.svg'
import newflats from './img/newflat.svg'
import { Skeleton } from '../Loader'

export const jobTypes = (bonuses) => [
  {
    id: 0,
    title: 'Ипотека',
    condition: (
      <span>
        Помогайте клиентам оформить ипотеку и&nbsp;зарабатывайте на&nbsp;сделках
      </span>
    ),
    reward: <>{bonuses.mortgage || <Skeleton width={'70px'} />}%</>,
    rewardDesc: <span>от&nbsp;суммы кредита</span>,
    image: bank,
  },
  {
    id: 1,
    title: 'Новостройки',
    condition: (
      <span>
        Помогайте клиентам приобрести жильё и&nbsp;зарабатывайте на&nbsp;продаже
        недвижимости
      </span>
    ),
    reward: <>{bonuses.newflats || <Skeleton width={'70px'} />}%</>,
    rewardDesc: <span>от&nbsp;стоимости недвижимости</span>,
    image: newflats,
  },
]
