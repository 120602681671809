import React, { useEffect, useState } from 'react'
import { useMount, useUnmount } from '../../hooks'
import * as styles from './youtubePlayer.module.less'

export const playerVarsDefault = {
  autoplay: 0,
  controls: 2,
  iv_load_policy: 3,
  modestbranding: 1,
  rel: 0,
  showinfo: 0,
  enablejsapi: 1,
  origin: typeof window !== 'undefined' && window.location,
}

export const YoutubePlayer = ({
  data,
  id,
  width = '100%',
  height = '100%',
  onPlay,
  playerVars,
  mute,
  pause,
}) => {
  const [player, setPlayer] = useState()
  const [options, setPlayerVars] = useState(playerVarsDefault)

  const youtubeId =
    id ||
    (data && data.attributes.src && data.attributes.src.includes('youtube.com')
      ? data.attributes.src.split('v=')[1]
      : data.attributes.src.replace(/^.+\/([^/]+)$/, '$1'))

  const lastPosition = 0

  const loadVideo = () => {
    const _vars = { ...playerVars, ...playerVarsDefault }
    new window.YT.Player(`video-${youtubeId}`, {
      width,
      height,
      videoId: youtubeId,
      events: {
        onReady: onPlayerReady,
      },
      playerVars: _vars,
    })

    setPlayerVars(_vars)
  }

  const onPlayerReady = (event) => {
    setPlayer(event.target)

    const duration = event.target.getDuration()

    lastPosition &&
      event.target.seekTo(
        duration <= Number(lastPosition) + 1 ? 0 : lastPosition,
        true
      )

    options.autoplay && event.target.playVideo()

    mute && event.target.mute()
  }

  useEffect(() => {
    if (!player) return

    pause ? player.pauseVideo() : player.playVideo()
  }, [pause])

  useMount(() => {
    if (!window.YT) {
      const tag = document.createElement('script')
      tag.src = '//www.youtube.com/iframe_api'

      window.onYouTubeIframeAPIReady = loadVideo

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    } else {
      loadVideo()
    }

    onPlay && onPlay()
  })

  useUnmount(() => {
    if (!player) return
    player.stopVideo && player.stopVideo()
    setPlayer(null)
  })
  return (
    <div className={styles.youtubePlayer}>
      <div id={`video-${youtubeId}`} />
    </div>
  )
}
