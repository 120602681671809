import React, { useState, useCallback, useContext } from 'react'
import cn from 'classnames'
import PopupRegistration from '../PopupRegistration'
import { useAnalytics } from '../../context/ga'

import * as styles from './forgetExcel.module.less'
import poster from './img/poster.jpg'
import screencast from './video/screencast2.mp4'
import screencastMobile from './video/mobile.mp4'
import { useScreenSize } from '../../hooks'
import { goToPlatform } from '../AuthState/helpers'
import { AuthContext } from '../AuthState/context'

export const ForgetExcel = () => {
  const auth = useContext(AuthContext)

  const [visible, handleVisiblePopup] = useState({
    isOpen: false,
    analyticEventNumb: 0,
  })
  const handleOpenAnalytics = useAnalytics({
    eventCategory: 'btnAccess',
    eventLabel: 'middleScr',
    clickText: 'Зарегистрироваться',
  })
  const handleOpen = useCallback(() => {
    handleVisiblePopup({ isOpen: true, analyticEventNumb: 4 })
    handleOpenAnalytics()
  }, [handleOpenAnalytics])

  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isMobileDevice = isMobile || isSmallTablet

  return (
    <section
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr',
        'text-center'
      )}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Забудьте про Excel</h2>

        <p className={styles.subTitle}>
          Заявки клиентов по&nbsp;всем банкам всегда на&nbsp;виду. Отслеживайте
          статусы, получайте напоминания и&nbsp;работайте с&nbsp;документами
          в&nbsp;едином личном кабинете.
        </p>

        <div className={styles.body}>
          <div className={styles.animation}>
            <div className={styles.animation__header} />
            <video
              src={screencast}
              className={styles.video}
              loop
              muted
              autoPlay={'autoplay'}
              poster={poster}
            />

            <button
              className={cn(styles.btn, 'btn', 'btn-primary')}
              onClick={auth.isAuthenticated ? goToPlatform : handleOpen}
            >
              {auth.isAuthenticated ? 'Войти' : 'Зарегистрироваться'}
            </button>
          </div>
        </div>
      </div>

      <PopupRegistration
        onClose={handleVisiblePopup}
        visible={visible}
        gaTag={'middleScrn'}
      />
    </section>
  )
}

export default ForgetExcel
