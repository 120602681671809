import React from 'react'

import * as styles from './jobType.module.less'

const JobType = ({ data }) => (
  <div className={styles.jobType}>
    <div className={styles.jobType__image}>
      <img src={data.image} alt={data.title} />
    </div>
    <div className={styles.jobType__title}>{data.title}</div>

    <div className={styles.jobType__condition}>{data.condition}</div>

    <div className={styles.jobType__reward}>
      <span>до</span>
      {data.reward}
    </div>
    <div className={styles.jobType__rewardDesc}>{data.rewardDesc}</div>
  </div>
)

export default JobType
