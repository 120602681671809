import React, { useCallback, useContext, useState } from 'react'
import { useAnalytics } from '../../context/ga'
import { eventSource } from './entity'
import cn from 'classnames'
import * as styles from './digitalMortgage.module.less'
import BonusContext from '../../context/bonuses'

export const Card = ({ data }) => {
  const [isActive, setIsActive] = useState(false)
  const bonuses = useContext(BonusContext)

  const openAnalytics = useAnalytics(eventSource(bonuses)[data.blockName], [
    data.blockName,
  ])

  const closeAnalytics = useAnalytics(
    { eventAction: 'close', ...eventSource[data.blockName] },
    [data.blockName]
  )

  const handleOpen = useCallback(() => {
    openAnalytics()
    setIsActive(true)
  }, [openAnalytics])

  const handleClose = useCallback(() => {
    closeAnalytics()
    setIsActive(false)
  }, [closeAnalytics])

  return (
    <div className={cn(styles.item, styles[`item${data.blockName}`])}>
      <div
        className={cn(
          styles.item__back,
          { [styles.item__backActive]: isActive },
          styles.card,
          styles.cardBack
        )}
      >
        <div className={cn(styles.item__header, styles.item__headerBack)}>
          {data.backTitle}
        </div>

        <div className={cn(styles.item__textBack)}>{data.backText}</div>

        <div className={styles.card__close} onClick={handleClose} />
      </div>

      <div
        className={cn(
          styles.item__front,
          { [styles.item__frontActive]: !isActive },
          styles.card,
          styles[`card${data.blockName}`]
        )}
        onClick={handleOpen}
      >
        <div
          className={cn(
            styles.item__image,
            styles[`item__image${data.blockName}`]
          )}
        />

        <div
          className={cn(
            styles.item__header,
            styles[`item__header${data.blockName}`]
          )}
        >
          {data.fronTitle}
        </div>

        <div className={styles.item__more}>{data.link}</div>
      </div>
    </div>
  )
}
