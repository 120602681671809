import React, { useContext } from 'react'
import cn from 'classnames'
import JobType from './JobType'

import { jobTypes } from './entity'
import * as styles from './jobTypes.module.less'
import star from './img/star.svg'
import BonusContext from '../../context/bonuses'

export const JobTypes = () => {
  const bonuses = useContext(BonusContext)

  return (
    <section
      id={'jobType'}
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr',
        'text-center'
      )}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          Cамое высокое
          <br /> вознаграждение на&nbsp;рынке
        </h2>
        <div className={styles.body}>
          {jobTypes(bonuses).map((jobType, index) => (
            <JobType key={index} data={jobType} />
          ))}
        </div>
        <div className={styles.banner__wrap}>
          <div className={styles.banner}>
            <img src={star} alt={'star'} />
            <div>
              <div className={styles.title}>
                Берите на&nbsp;себя меньше работы
              </div>
              <div className={styles.desc}>
                Если не&nbsp;хотите вести клиента до&nbsp;сделки, передайте его
                контакты нам&nbsp;&mdash; всё остальное мы&nbsp;сделаем сами.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobTypes
