import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import Slider from 'react-slick'
import cn from 'classnames'
import Videos from '../Videos'

import * as styles from './tablet.module.less'

const videoNames = ['first', 'second', 'third', 'fourth', 'fifth']

const renderDescription = (list = [], activeSlide) =>
  list.map(({ title, description }, index) => (
    <div id={`step_${index}`} key={index} className={styles.listItem__wrapper}>
      <div
        className={cn(styles.listItem, {
          [styles.listItemActive]: activeSlide === videoNames[index],
        })}
      >
        <span className={styles.number}>{index + 1}</span>
        <div className={styles.textContainer}>
          <div className={styles.title}>{title}</div>
          <div
            className={cn(styles.description, {
              [styles.descriptionActive]: activeSlide === videoNames[index],
            })}
          >
            {description}
          </div>
        </div>
      </div>
    </div>
  ))

const TabletSlider = ({ list }) => {
  const ref = useRef()
  const [activeSlide, setActiveSlide] = useState('first')
  const onBeforeChange = useCallback(
    (current, next) => setActiveSlide(videoNames[next]),
    []
  )

  const settings = useMemo(
    () => ({
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      arrows: false,
      infinite: false,
      beforeChange: onBeforeChange,
    }),
    []
  )

  return (
    <div>
      <div className={styles.video__wrapper}>
        <div className={styles.video__header} />

        <Videos step={activeSlide} />
      </div>

      <div className={styles.slider__wrapper}>
        <Slider ref={ref} className={cn('try-us-mobile-slider')} {...settings}>
          {renderDescription(list, activeSlide)}
        </Slider>
      </div>
    </div>
  )
}

export default TabletSlider
