// extracted by mini-css-extract-plugin
export var description = "tablet-module--description--34380";
export var descriptionActive = "tablet-module--description--active--47102";
export var listItem = "tablet-module--list-item--af35c";
export var listItemActive = "tablet-module--list-item--active--d4ac8";
export var listItem__wrapper = "tablet-module--list-item__wrapper--36c06";
export var number = "tablet-module--number--ea90a";
export var slider__wrapper = "tablet-module--slider__wrapper--d02af";
export var textContainer = "tablet-module--textContainer--4b3c3";
export var title = "tablet-module--title--eef84";
export var video__header = "tablet-module--video__header--7ce71";
export var video__wrapper = "tablet-module--video__wrapper--de3df";