import React, { useState, useCallback, useMemo } from 'react'
import cn from 'classnames'
import Videos from '../Videos'
import { useAnalytics } from '../../../../context/ga'
import { dealStepsSource } from '../../entity'
import * as styles from './desktop.module.less'

const StepsItem = ({ data, isActive, onItemClick }) => {
  const stepText = useMemo(
    () =>
      dealStepsSource[data.id] ? dealStepsSource[data.id].analytTitle : '',
    [dealStepsSource, data.id]
  )

  const handleChangeAnalytics = useAnalytics({
    eventCategory: 'ciklSdelki',
    eventLabel: stepText,
    clickText: stepText,
  })

  const onClick = useCallback(() => {
    handleChangeAnalytics()
    onItemClick(data)
  }, [data, handleChangeAnalytics])

  return (
    <div
      className={cn(styles.step, { [styles.stepActive]: isActive })}
      onClick={onClick}
    >
      <div className={styles.step__order}>{data.id + 1}</div>

      <div className={styles.step__text}>
        <div
          className={cn(styles.step__title, {
            [styles.step__titleActive]: isActive,
          })}
        >
          {data.title}
        </div>
        <div
          className={cn(styles.step__description, {
            [styles.step__descriptionActive]: isActive,
          })}
        >
          {data.description}
        </div>
      </div>
    </div>
  )
}

const StepsList = ({ list = [], active, onChangeActive = () => {} }) =>
  list.map((item) => (
    <StepsItem
      key={item.id}
      isActive={item.slide === active}
      data={item}
      onItemClick={onChangeActive}
    />
  ))

export const DealSteps = ({ list = [] }) => {
  const [activeSlide, setActiveSlide] = useState('first')

  const setActiveStep = useCallback(({ slide }) => {
    setActiveSlide(slide)
  }, [])

  return (
    <>
      <div className={styles.sidebar}>
        <StepsList
          list={list}
          active={activeSlide}
          onChangeActive={setActiveStep}
        />
      </div>

      <div className={cn(styles.video__wrapper)}>
        <div className={styles.video__header} />
        <Videos step={activeSlide} />
      </div>
    </>
  )
}

export default DealSteps
