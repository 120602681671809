import React from 'react'
import * as styles from './otherSmi.module.less'

import bankiru from './img/bankiru.svg'
import interfax from './img/interfax.svg'

export const OtherSmi = () => {
  return (
    <div className={styles.mobileArticleRow}>
      <p className={styles.mobileArticleRow_text}>В других изданиях</p>
      <div className={styles.mobileBottomRow}>
        <a
          href="https://www.banki.ru/news/pressreleases/?id=10916553"
          noreferrer="true"
          noopener="true"
          target="_blank"
        >
          <img
            src={bankiru}
            className={styles.mobileArticleRow_img}
            loading="lazy"
          />
        </a>

        <a
          href="https://www.interfax.ru/pressreleases/694518"
          noreferrer="true"
          noopener="true"
          target="_blank"
        >
          <img
            src={interfax}
            className={styles.mobileArticleRow_img}
            loading="lazy"
          />
        </a>
      </div>
    </div>
  )
}
