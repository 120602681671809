import React, { useCallback, useState, useMemo } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'
import cn from 'classnames'

import { useAnalytics } from '../../../context/ga'

import { questions as defaultQuestion } from '../config'
import * as styles from './accordion.module.less'

const AccordionComponentItem = ({ title, description, uuid }) => {
  return (
    <AccordionItem className={styles.item} uuid={uuid}>
      <AccordionItemState>
        {({ expanded }) => (
          <>
            <AccordionItemHeading className={styles.itemTitle}>
              <AccordionItemButton>
                <i className={styles.arrow} role="presentation" />
                <h4 className={styles.title}>{title}</h4>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              className={cn(styles.itemBody, {
                [styles.itemBodyHide]: !expanded,
              })}
            >
              <p className={styles.description}>{description}</p>
            </AccordionItemPanel>
          </>
        )}
      </AccordionItemState>
    </AccordionItem>
  )
}

const AccordionColumn = ({ list = [], isEven = false }) => {
  const filteredList = useMemo(
    () => list.filter((item, index) => !!(index % 2) === isEven),
    [isEven, list]
  )

  return (
    <div className={styles.column}>
      {filteredList.map((item) => (
        <AccordionComponentItem
          key={item.id}
          uuid={item.id}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
  )
}

const AccordionComponent = () => {
  const [questions, setQuestions] = useState(defaultQuestion)
  const handleAnalytics = useAnalytics((clickText = '') => ({
    eventCategory: 'question',
    clickText,
  }))
  const handleChange = useCallback(
    (change = []) => {
      setQuestions(
        questions.map((item) => {
          if (!change.includes(item.id) || item.isSent) return item

          handleAnalytics(item.analyticsText)

          return { ...item, isSent: true }
        })
      )
    },
    [questions, handleAnalytics]
  )

  return (
    <Accordion
      onChange={handleChange}
      accordion={false}
      className={styles.accordion}
      allowZeroExpanded
    >
      <AccordionColumn list={questions} />

      <AccordionColumn isEven list={questions} />
    </Accordion>
  )
}

export default AccordionComponent
