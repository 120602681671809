import React, { useState } from 'react'
import cn from 'classnames'

import Header from './Header'
import { TextBlock1 } from './TextBlock'
import PopupRegistration from '../PopupRegistration'

import * as styles from './headsection.module.less'

const HeadSection = ({ location }) => {
  const [visible, handleVisiblePopup] = useState({
    isOpen: !!location?.hash?.includes('registration'),
    analyticEventNumb: 0,
  })

  return (
    <section className={cn(styles.container, styles.imageContainer)}>
      <Header />

      <section className={styles.container}>
        <TextBlock1 handleVisiblePopup={handleVisiblePopup} />
      </section>

      <PopupRegistration
        onClose={handleVisiblePopup}
        visible={visible}
        gaTag={'mainScrn'}
      />
    </section>
  )
}

export default HeadSection
