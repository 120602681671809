import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AnalyticsProvider } from '../context/ga'
import { ModalProvider } from '../context/modal'
import gaInit from '../helpers/gaInit'
import { vkPixelInit, VK_PIXEL_ID } from '../helpers/vkPixel'
import { seo } from '../seo'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'keen-slider/keen-slider.min.css'
import '../styles/index.less'

import '../configs'

import FixedBlock from '../components/FixedBlock'
import HeadSection from '../components/HeadSection'
import DigitalMortgage from '../components/DigitalMortgage'
import PartnerBanks from '../components/PartnerBanks'
import DealSteps from '../components/DealSteps'
import JobTypes from '../components/JobTypes'
import ForgetExcel from '../components/ForgetExcel'
import TryUs from '../components/TryUs'
import Questions from '../components/Questions'
// import StartWork from '../components/StartWork';
import Footer from '../components/Footer'
import WhatToDo from '../components/WhatToDo'
import SchemaOrg from '../components/SchemaOrg'
import News from '../components/News'
// import OnlineShowBanner from '../components/OnlineShowBanner';
import PrivacyBanner from '../components/PrivacyBanner'
import MobileMenu from '../components/MobileMenu'
import { useScreenSize } from '../hooks'
import { AreaProvider } from '../context/area'
import { AuthProvider } from '../components/AuthState/context'
import { isDefaultArea } from '../helpers/area'
// import ContestBanner from '../components/Contest/Banner'
import { BonusesProvider } from '../context/bonuses'

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  if (isDefaultArea()) require('../helpers/calltouch')
}

const IndexPage = ({ location }) => {
  const [isShowPopup, setIsShowPopup] = useState(false)

  const isMobile = useScreenSize('mobileMenu')

  useEffect(() => {
    if (isDefaultArea()) {
      setTimeout(function () {
        if (document.getElementById('amo-livechat')) {
          const amoStyles = document.getElementById('amo-livechat').nextSibling
          amoStyles.innerText = `
      html.amo-livechat-iframe--shq-ixy .amo-brand {
        display: none;
      }
      @media (max-width: 450px) {
        html.amo-livechat-iframe--shq-ixy-active {
          overflow: hidden !important;
        }
        html.amo-livechat-iframe--shq-ixy-active > body {
          background: #fff !important;
          overflow: hidden !important;
        }
        .amo-livechat_chat {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-height: unset;
          min-height: unset;
          border-radius: 0;
        }
      }
      `
        }
      }, 7000)
    }
  }, [])

  return (
    <AuthProvider>
      <ModalProvider>
        <AreaProvider>
          <AnalyticsProvider>
            <BonusesProvider>
              <Helmet defer={false}>
                <title>
                  Ипотека.Центр - сервис для заработка на ипотеке для риэлтеров
                  и агентств недвижимости по партнерской программе
                </title>

                <meta name="image" content={seo.image} />
                <meta property="og:image" content={seo.image} />

                <meta property="og:title" content={seo.title} />
                <meta property="title" content={seo.title} />

                <meta name="description" content={seo.description} />
                <meta property="og:description" content={seo.description} />

                <meta property="og:url" content={seo.url} />
                <meta property="url" content={seo.url} />

                <meta name="yandex-verification" content="bccc5decc3768c84" />
                {process.env.GATSBY_ENVIRONMENT !== 'production' && (
                  <meta name="robots" content="noindex" />
                )}
                <meta
                  name="facebook-domain-verification"
                  content="cqxdvks4lxi2ylxcw7uusmu5vzebkp"
                />

                <link rel="dns-prefetch" href="https://www.youtube.com" />
                <link
                  rel="sitemap"
                  type="application/xml"
                  href="/sitemap.xml"
                />

                <script>{gaInit()}</script>
                <script>{vkPixelInit()}</script>
                <noscript>
                  {`<img src="https://vk.com/rtrg?p=${VK_PIXEL_ID}" style="position:fixed; left:-999px;" alt=""/>`}
                </noscript>
              </Helmet>

              <FixedBlock>
                {/* <OnlineShowBanner /> */}
                {/* <ContestBanner /> */}
                <HeadSection location={location} />
                {isMobile ? <MobileMenu /> : null}
                <DigitalMortgage />
                <WhatToDo />
                <PartnerBanks />
                <DealSteps />
                <JobTypes />
                <ForgetExcel />
                <TryUs />
                <Questions />
                <News />
                {/* <StartWork /> */}
                <Footer />
                <SchemaOrg />
                <PrivacyBanner
                  isShowPopup={isShowPopup}
                  setIsShowPopup={setIsShowPopup}
                />
              </FixedBlock>
            </BonusesProvider>
          </AnalyticsProvider>
        </AreaProvider>
      </ModalProvider>
    </AuthProvider>
  )
}

export default IndexPage
