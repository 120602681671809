// extracted by mini-css-extract-plugin
export var active = "news-module--active--9c1bd";
export var arrow = "news-module--arrow--fbe85";
export var arrowDisabled = "news-module--arrowDisabled--70257";
export var arrowLeft = "news-module--arrowLeft--aae19";
export var arrowRight = "news-module--arrowRight--94957";
export var articleBlock = "news-module--articleBlock--31644";
export var articleBlock_img = "news-module--articleBlock_img--59e23";
export var articleText = "news-module--articleText--33bae";
export var body = "news-module--body--14a94";
export var container = "news-module--container--cb81c";
export var dot = "news-module--dot--cecd9";
export var dots = "news-module--dots--b4395";
export var navigationWrapper = "news-module--navigationWrapper--460f1";
export var newsSlider = "news-module--newsSlider--813cc";
export var skolkovo = "news-module--skolkovo--f316d";
export var slide = "news-module--slide--ab1fa";
export var title = "news-module--title--ec5b2";