import React from 'react'
import { Skeleton } from '../Loader'

export const cards = (bonuses) => [
  {
    link: 'Подробнее',
    fronTitle: (
      <span>
        Зарабатывайте до&nbsp;
        {bonuses.max ? (
          <>{bonuses.max}%</>
        ) : (
          <Skeleton width={'40px'} color="#d2d2d2" />
        )}{' '}
        от&nbsp;сделок с&nbsp;недвижимостью и&nbsp;ипотекой
      </span>
    ),
    backTitle: <span>Самое высокое агентское вознаграждение на рынке</span>,
    backText: (
      <span>
        Зарабатывайте до&nbsp;{bonuses.mortgage || <Skeleton width={'20px'} />}%
        от&nbsp;сделок с&nbsp;ипотекой и&nbsp;до&nbsp;
        {bonuses.newflats || <Skeleton width={'20px'} />}% от&nbsp;продажи
        недвижимости. Благодаря тому, что все этапы получения ипотеки проходят
        онлайн, наши партнеры&nbsp;&mdash; банки и&nbsp;застройщики&nbsp;&mdash;
        сократили затраты и&nbsp;готовы больше платить за&nbsp;сделки
        на&nbsp;платформе. Для бизнес-партнеров наш сервис полностью бесплатен.
      </span>
    ),
    blockName: 'First',
  },
  {
    link: 'Узнать больше',
    fronTitle: <span>Работайте из&nbsp;любой точки планеты</span>,
    backTitle: <span>Полная свобода</span>,
    backText: (
      <span>
        Вся работа с&nbsp;заявкой ведётся онлайн. Вы&nbsp;можете работать когда
        и&nbsp;где угодно, отслеживать процессы, получать напоминания
        и&nbsp;статистику, иметь под рукой документы клиентов, решения банков
        и&nbsp;бронировать квартиры у&nbsp;застройщиков. Работайте из&nbsp;дома,
        любимого кафе или из&nbsp;любой точки мира.
      </span>
    ),
    blockName: 'Second',
  },
  {
    link: 'За счёт чего?',
    fronTitle: <span>Уделяйте время клиентам, а&nbsp;не&nbsp;рутине</span>,
    backTitle: <span>Без рутины</span>,
    backText: (
      <span>
        Мы&nbsp;автоматизируем рутинную работу и&nbsp;организуем взаимодействие
        с&nbsp;партнерами через одно окно. Единую анкету принимают все наши
        банки-партнеры, а&nbsp;предложения от&nbsp;застройщиков легко найти
        на&nbsp;нашей платформе. Теперь можно больше времени уделять клиентам,
        а&nbsp;не&nbsp;рутине.
      </span>
    ),
    blockName: 'Third',
  },
  {
    link: 'Зачем?',
    fronTitle: <span>Личный куратор</span>,
    backTitle: <span>Онлайн поддержка</span>,
    backText: (
      <span>
        Личный куратор-эксперт поможет в&nbsp;сложной ситуации. Его опыт
        и&nbsp;знания позволят ещё быстрее достигать крутых результатов.
      </span>
    ),
    blockName: 'Fourth',
  },
  {
    link: 'Так можно было?',
    fronTitle: (
      <span>
        Банки, застройщики, оценочные и&nbsp;страховые компании
        в&nbsp;одном&nbsp;окне
      </span>
    ),
    backTitle: <span>Одно окно ко&nbsp;всем участникам</span>,
    backText: (
      <>
        {' '}
        <p>
          На&nbsp;платформе проходят все этапы сделки с&nbsp;недвижимостью.
          Поиск объекта, фиксация клиента, согласование заёмщика с&nbsp;банками,
          заказ отчёта об&nbsp;оценке, оформление страховки и&nbsp;назначение
          сделки.{' '}
        </p>
        <p>
          {' '}
          Больше не&nbsp;нужно переключаться между разными системами, следить
          за&nbsp;переписками и&nbsp;вести собственные таблички&nbsp;&mdash; всё
          для работы, есть в&nbsp;вашем личном кабинете.
        </p>{' '}
      </>
    ),
    blockName: 'Fifth',
  },
  {
    link: 'Как заработать?',
    fronTitle: <span>Зарабатывайте на новостройках</span>,
    backTitle: <span>Новостройки</span>,
    backText: (
      <span>
        Мы&nbsp;объединили подбор недвижимости и&nbsp;выдачу ипотеки
        на&nbsp;одной платформе и&nbsp;предлагаем объекты от&nbsp;самых надёжных
        застройщиков России. Помогайте клиенту приобрести квартиру или
        апартаменты в&nbsp;новостройке и&nbsp;зарабатывайте дополнительно
        до&nbsp;{bonuses.newflats || <Skeleton width={'20px'} />}%
        от&nbsp;стоимости объекта.
      </span>
    ),
    blockName: 'Sixth',
  },
  {
    link: 'А как?',
    fronTitle: <span>Помогайте инвестировать в зарубежное жилье</span>,
    backTitle: <span>Зарубежная недвижимость</span>,
    backText: (
      <span>
        На&nbsp;платформе саккумулированы лучшие предложения застройщиков OAЭ
        и&nbsp;Турции. Мы&nbsp;сделали бесшовный процесс подбора и&nbsp;покупки
        зарубежной недвижимости. Находите наиболее подходящие варианты для
        клиента и&nbsp;получайте вознаграждение за&nbsp;сделку.
      </span>
    ),
    blockName: 'Seven',
  },
]

export const eventSource = (bonuses) => ({
  First: {
    eventCategory: 'cards',
    eventLabel: '1',
    clickText: `Зарабатываете до ${bonuses.max}% от сделок с недвижимостью и ипотекой,`,
  },
  Second: {
    eventCategory: 'cards',
    eventLabel: '2',
    clickText: 'Работайте из любой точки планеты',
  },
  Third: {
    eventCategory: 'cards',
    eventLabel: '3',
    clickText: 'Уделяйте время клиентам, а не рутине',
  },
  Fourth: {
    eventCategory: 'cards',
    eventLabel: '4',
    clickText: 'Личный куратор',
  },
  Fifth: {
    eventCategory: 'cards',
    eventLabel: '5',
    clickText: 'Банки, оценочные и страховые компании в одном окне',
  },
  Sixth: {
    eventCategory: 'cards',
    eventLabel: '6',
    clickText: 'Зарабатывайте на новостройках',
  },
  Seven: {
    eventCategory: 'cards',
    eventLabel: '7',
    clickText: 'Помогайте инвестировать в зарубежное жилье',
  },
})
