import React, { Component } from 'react'
import cn from 'classnames'

import { screens } from '../../../constants'
import AnalyticsContext from '../../../context/ga'
import { analytics } from '../../../helpers/googleAnalytics'

import * as styles from './textBlock.module.less'

class TextBlock2 extends Component {
  state = {
    isOpen: false,
  }

  static contextType = AnalyticsContext

  get isMobile() {
    return (
      typeof window !== `undefined` && window.innerWidth < screens.screenMMax
    )
  }

  onClose = () => {
    this.setState({
      isOpen: false,
    })

    analytics({
      ClientId: this.context.ClientId,
      eventCategory: 'popup',
      eventLabel: 'close',
    })()
  }

  render() {
    const { handleVisiblePopup } = this.props

    return (
      <div className={cn(styles.container, 'container-padding--hr')}>
        <h2>Конкурс для ипотечных менеджеров</h2>
        <p>
          Зарегистрируйтесь на платформе, войдите в ТОП-5 по количеству выдач
          до нового года и получите классные призы.
        </p>
        <button
          onClick={() => {
            handleVisiblePopup({ isOpen: true, analyticEventNumb: 5 })
            analytics({
              ClientId: this.context.ClientId,
              eventCategory: 'Interection',
              eventLabel: 'btn-dostup',
              clickText: 'Зарегистрироваться и принять участие',
            })()
          }}
          className={'btn btn-primary btn-lg'}
        >
          {this.isMobile
            ? 'Зарегистрироваться'
            : 'Зарегистрироваться и принять участие'}
        </button>
      </div>
    )
  }
}
export { TextBlock2 }
