// extracted by mini-css-extract-plugin
export var hideOn102 = "partner-module--hideOn102--f7a96";
export var partner = "partner-module--partner--ef2a7";
export var partnerModal = "partner-module--partner--modal--76b38";
export var partner__avatar = "partner-module--partner__avatar--66417";
export var partner__body = "partner-module--partner__body--03184";
export var partner__bodyModal = "partner-module--partner__body--modal--155b5";
export var partner__header = "partner-module--partner__header--76dc6";
export var partner__ignoreClick = "partner-module--partner__ignoreClick--676e0";
export var partner__more = "partner-module--partner__more--97ea1";
export var partner__name = "partner-module--partner__name--60e6a";
export var partner__nameWrap = "partner-module--partner__name-wrap--909df";
export var partner__position = "partner-module--partner__position--99b33";
export var partner__review = "partner-module--partner__review--11ab5";
export var partner__reviewFill = "partner-module--partner__review--fill--dfb96";
export var partner__social = "partner-module--partner__social--5cb54";
export var partner__socialFacebook = "partner-module--partner__social--facebook--d6209";
export var partner__socialInstagram = "partner-module--partner__social--instagram--cfc67";
export var partner__socialVk = "partner-module--partner__social--vk--b2de8";