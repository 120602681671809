import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import cn from 'classnames'

import subtitles from 'file-loader!./video/5years.vtt'
import * as styles from './textBlock.module.less'
import { useRefEffect } from '../../../helpers/hooks'
import { useAnalytics } from '../../../context/ga'
import { Loader, Skeleton } from '../../Loader'

import screencast from './video/5years.mp4'
import playIcon from './img/play.svg'
import { AuthContext } from '../../AuthState/context'
import BonusContext from '../../../context/bonuses'
import { goToPlatform } from '../../AuthState/helpers'

const TextBlock1 = ({ handleVisiblePopup = () => {} }) => {
  const auth = useContext(AuthContext)
  const bonuses = useContext(BonusContext)

  const handleAnalytics = useAnalytics({
    eventCategory: 'btnAccess',
    eventLabel: 'mainScrn',
    clickText: 'Зарегистрироваться',
  })

  const handleOpen = useCallback(() => {
    handleAnalytics()

    handleVisiblePopup({ isOpen: true, analyticEventNumb: 1 })
  }, [handleAnalytics])

  return (
    <div className={styles.container__wrapper}>
      <div className={cn(styles.container, 'container-padding--hr')}>
        <h1>
          Платформа для&nbsp;профессионалов рынка ипотеки и&nbsp;недвижимости
        </h1>

        <p>
          Помогайте своим клиентам приобрести недвижимость мечты
          и&nbsp;зарабатывайте до&nbsp;
          {bonuses.max ? (
            <>{bonuses.max}%</>
          ) : (
            <Skeleton width={'48px'} color="#7D5CE4" />
          )}{' '}
          от&nbsp;суммы сделок.
        </p>

        <button
          onClick={auth.isAuthenticated ? goToPlatform : handleOpen}
          className={cn(styles.btn, 'btn', 'btn-primary', 'btn-lg')}
          id={'sectionSignUpBtn'}
        >
          {auth.isAuthenticated ? 'Войти' : 'Зарегистрироваться'}
        </button>

        <div className={styles.video__wrapper}>
          <Video />
        </div>
      </div>
    </div>
  )
}

const VideoPlay = ({ onClick = () => {} }) => {
  return (
    <img
      onClick={onClick}
      src={playIcon}
      className={styles.video__play}
      loading="lazy"
    />
  )
}

const Video = () => {
  const ref = useRef(null)
  const [played, setPlayed] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [duration, setDuration] = useState(0)
  const [controls, setControls] = useState(false)
  const showLoader = useMemo(() => !loaded, [loaded])
  const showPlay = useMemo(() => loaded && !played, [loaded, played])
  const isModile = useMediaQuery({ query: '(max-width: 1024px)' })
  const playedStr = useDelay(duration, played)
  const handlePlay = useCallback(() => ref.current.play())

  useRefEffect(
    ref,
    (video) => {
      const onShowControl = () => {
        setControls(true)
      }
      const onHideControl = () => {
        setControls(false)
      }
      const onTimeUpdated = () => {
        setPlayed(Math.round(video.currentTime))
      }
      const onLoadComplete = () => {
        setDuration(Math.round(video.duration))
      }
      const onCanPlay = () => {
        setLoaded(true)

        !isModile && handlePlay()
      }

      video.addEventListener('loadeddata', onCanPlay, { passive: true })
      video.addEventListener('mouseenter', onShowControl, { passive: true })
      video.addEventListener('mouseleave', onHideControl, { passive: true })
      video.addEventListener('loadedmetadata', onLoadComplete, {
        passive: true,
      })
      video.addEventListener('timeupdate', onTimeUpdated, { passive: true })

      return () => {
        video.removeEventListener('loadeddata', onCanPlay, { passive: true })
        video.removeEventListener('mouseenter', onShowControl, {
          passive: true,
        })
        video.removeEventListener('mouseleave', onHideControl, {
          passive: true,
        })
        video.removeEventListener('loadedmetadata', onLoadComplete, {
          passive: true,
        })
        video.removeEventListener('timeupdate', onTimeUpdated, {
          passive: true,
        })
      }
    },
    []
  )

  return (
    <div className={styles.video__container}>
      {showLoader && <Loader color="#fff" />}
      {showPlay && <VideoPlay onClick={handlePlay} />}

      <video
        ref={ref}
        controls={controls}
        className={styles.video}
        loop
        muted
        autoPlay
        playsInline
        preload={'auto'}
      >
        <source src={screencast} type="video/mp4" />
        <track
          label="Russia"
          kind="subtitles"
          srcLang="ru"
          src={subtitles}
          default
        />
      </video>
      {loaded && <div className={styles.video__countdown}>{playedStr}</div>}
    </div>
  )
}

const useDelay = (duration, played) => {
  return useMemo(() => {
    if (duration < 1) return null

    const delayed = parseTime(duration - played)

    return formatTime(delayed)
  }, [duration, played])
}

const parseTime = (timeInSeconds) => {
  return {
    minutes: Math.floor(timeInSeconds / 60),
    seconds: Math.floor(timeInSeconds % 60),
  }
}

const formatTime = (time) => {
  const seconds = ('00' + time.seconds).slice(-2)
  return `${time.minutes}:${seconds}`
}

export { TextBlock1 }
