// extracted by mini-css-extract-plugin
export var animation = "forgetExcel-module--animation--5aca1";
export var animation__header = "forgetExcel-module--animation__header--29477";
export var body = "forgetExcel-module--body--67118";
export var btn = "forgetExcel-module--btn--c91fd";
export var container = "forgetExcel-module--container--0d578";
export var ratio = "forgetExcel-module--ratio--5b502";
export var ratio43 = "forgetExcel-module--ratio-4-3--5920c";
export var ratioContent = "forgetExcel-module--ratio-content--7f4e2";
export var ratioInner = "forgetExcel-module--ratio-inner--10088";
export var subTitle = "forgetExcel-module--subTitle--a4cf6";
export var title = "forgetExcel-module--title--079dd";
export var video = "forgetExcel-module--video--54547";
export var wrapper = "forgetExcel-module--wrapper--916ac";