import React from 'react'

export const dealStepsSource = (bonuses) => [
  {
    id: 0,
    analytTitle: 'Сделайте расчёт в кредитном калькуляторе',
    title: <span>Сделайте расчёт в&nbsp;кредитном калькуляторе</span>,
    description: (
      <span>
        Все актуальные условия банков-партнёров собраны в&nbsp;единый
        смарт-калькулятор.
      </span>
    ),
    isActive: true,
    slide: 'first',
  },
  {
    id: 1,
    analytTitle: 'Получите одобрение в банках',
    title: <span>Получите одобрение в&nbsp;банках</span>,
    description: (
      <span>Заполните единую анкету клиента, которую примет любой банк.</span>
    ),
    slide: 'second',
  },
  {
    id: 2,
    analytTitle: 'Выберите и одобрите объект недвижимости',
    title: <span>Выберите и&nbsp;одобрите объект недвижимости</span>,
    description: (
      <span>
        Выберите объект, закажите оценку и&nbsp;страховку прямо
        на&nbsp;платформе или загрузите готовые.
      </span>
    ),
    slide: 'third',
  },
  {
    id: 3,
    analytTitle: 'Согласуйте дату и место сделки',
    title: <span>Согласуйте дату и&nbsp;место сделки</span>,
    description: (
      <span>
        В&nbsp;офис банка нужно прийти только один раз&nbsp;&mdash; на&nbsp;саму
        сделку.
      </span>
    ),
    slide: 'fourth',
  },
  {
    id: 4,
    analytTitle: 'Получите вознаграждение',
    title: (
      <span>
        Получите
        <br /> вознаграждение
      </span>
    ),
    description: (
      <span>
        Ваш бонус составит до&nbsp;{bonuses.mortgage}% от&nbsp;сделок
        с&nbsp;ипотекой и&nbsp;до&nbsp;{bonuses.newflats}% от&nbsp;продажи
        недвижимости.
      </span>
    ),
    slide: 'fifth',
  },
]
