import React from 'react'
import cn from 'classnames'

import { useAnalytics } from '../../../context/ga'

import * as styles from './partner.module.less'
import HeaderLink from './HeaderLink'

const Partner = ({
  isModal = false,
  data = {},
  onChange = () => {},
  onClick,
  isBigTablet = false,
}) => {
  const [handleClick, setHandleClick] = React.useState(() => {})
  const [url, setUrl] = React.useState('')
  const handleAnalytics = useAnalytics({
    eventCategory: 'review',
    eventLabel: data.social ? data.social.toLowerCase() : '',
  })

  React.useEffect(() => {
    setHandleClick(() => onClick)
    setUrl(data.socialUrl)
  }, [data.id])
  return (
    <div
      className={cn(styles.partner, { [styles.partnerModal]: isModal })}
      onClick={handleClick}
    >
      <div className={styles.partner__header}>
        <HeaderLink socialUrl={data.socialUrl} iconName={data.iconName} />
        <div>
          <a href={url} target={'_blank'} className="ingoreClick">
            <div
              className={cn(
                styles.partner__nameWrap,
                styles.partner__ignoreClick
              )}
              onClick={handleAnalytics}
            >
              <div
                className={cn(
                  styles.partner__name,
                  styles.partner__ignoreClick
                )}
              >
                {data.name}
              </div>

              {url && (
                <div
                  className={cn(
                    styles.partner__social,
                    styles.partner__ignoreClick,
                    {
                      [styles[`partner__social${data.social}`]]: data.social,
                    }
                  )}
                />
              )}
            </div>
          </a>

          <div className={styles.partner__position}>{data.position}</div>
        </div>
      </div>

      <div className={styles.partner__body}>
        <div
          className={cn(styles.partner__review, {
            [styles.partner__reviewFill]: isModal,
          })}
        >
          {isModal
            ? data.fullText
            : isBigTablet && data.id === 12
            ? data.tabletText
            : data.text}
        </div>

        {!isModal && (
          <div
            className={cn(styles.partner__more, styles.partner__ignoreClick)}
            onClick={onChange}
          >
            Читать полностью
          </div>
        )}
      </div>
    </div>
  )
}

export default Partner
