import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import AreaContext from './area'

const BonusContext = React.createContext({})

export const BonusesProvider = ({ children }) => {
  const { apiUrl } = useContext(AreaContext)
  const [bonuses, setBonuses] = useState([])
  const source = axios.CancelToken.source()

  useEffect(() => {
    axios({
      method: 'get',
      url: `${apiUrl}/api/v1/public/bonuses-of-partner`,
      cancelToken: source.token,
    }).then(({ data }) => {
      if (data) {
        const bonusesObj = data.reduce(
          (acc, { targetSubject, value }) => ({
            [targetSubject]: value,
            ...acc,
          }),
          {}
        )
        setBonuses(() => ({
          ...bonusesObj,
          ['max']: Number(bonusesObj.mortgage) + Number(bonusesObj.newflats),
        }))
      }
    })

    return () => {
      source.cancel()
    }
  }, [])

  return (
    <BonusContext.Provider value={bonuses}>{children}</BonusContext.Provider>
  )
}

export default BonusContext
