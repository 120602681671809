import React, { useCallback, useEffect } from 'react'
import {
  YoutubePlayer,
  playerVarsDefault,
} from '../YoutubePlayer/YoutubePlayer'
import { Popup, Overlay, Close, Player } from './atoms'

export const BlockClick = ({ children, tag, ...props }) => {
  const handler = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const TagName = tag || 'span'

  return (
    <TagName onClick={handler} {...props}>
      {children}
    </TagName>
  )
}

export const VideoPopup = ({
  data,
  opened,
  onClose,
  onPlay,
  iframe = false,
}) => {
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  if (!opened || !data) return null

  const youtubeId = data.attributes.src.replace(/^.+\/([^/]+)$/, '$1')

  return (
    <Popup>
      <Overlay onClick={onClose} />
      <Close onClick={onClose} />
      <BlockClick>
        <Player>
          {iframe ? (
            <iframe
              id={data.id}
              title={data.attributes.title}
              type="text/html"
              width="960"
              height="540"
              src={`https://www.youtube.com/embed/${youtubeId}?${Object.entries(
                playerVarsDefault
              )
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
            ></iframe>
          ) : (
            <YoutubePlayer data={data} id={youtubeId} onPlay={onPlay} />
          )}
        </Player>
      </BlockClick>
    </Popup>
  )
}
