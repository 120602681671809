// extracted by mini-css-extract-plugin
export var animation = "whatToDo-module--animation--1dc1b";
export var animationBlock = "whatToDo-module--animation-block--88f74";
export var animationBlockClient = "whatToDo-module--animation-block--client--d5b48";
export var animationBlockCompany = "whatToDo-module--animation-block--company--bdc1f";
export var animationDescription = "whatToDo-module--animation-description--f225a";
export var animationSecond = "whatToDo-module--animation--second--dee5e";
export var animationThird = "whatToDo-module--animation--third--3e274";
export var blockWrapper = "whatToDo-module--blockWrapper--a8cae";
export var body = "whatToDo-module--body--784a4";
export var description = "whatToDo-module--description--31d93";
export var descriptionBlock = "whatToDo-module--descriptionBlock--fdf1e";
export var descriptionMobileText = "whatToDo-module--descriptionMobileText--7d272";
export var descriptionNumber = "whatToDo-module--descriptionNumber--59453";
export var descriptionText = "whatToDo-module--descriptionText--3ca16";
export var descriptionWrapper = "whatToDo-module--descriptionWrapper--cca11";
export var fadeIn = "whatToDo-module--fadeIn--f165a";
export var fadeInRight = "whatToDo-module--fadeInRight--d6ade";
export var firstAnimation = "whatToDo-module--first-animation--3ae76";
export var lastBlockWrapper = "whatToDo-module--lastBlockWrapper--38945";
export var logo = "whatToDo-module--logo--f5805";
export var mainCenter = "whatToDo-module--mainCenter--eef92";
export var mainWrapper = "whatToDo-module--mainWrapper--f0a1e";
export var moveRight = "whatToDo-module--moveRight--d6949";
export var moveleft = "whatToDo-module--moveleft--b6cc4";
export var partner = "whatToDo-module--partner--340f5";
export var partnerArrow = "whatToDo-module--partner-arrow--db535";
export var partnerArrowUnder = "whatToDo-module--partner-arrow--under--f33b4";
export var partnerArrow__wrapper = "whatToDo-module--partner-arrow__wrapper--9556f";
export var partnerArrow__wrapperFifth = "whatToDo-module--partner-arrow__wrapper--fifth--3aa91";
export var partnerArrow__wrapperFirst = "whatToDo-module--partner-arrow__wrapper--first--63ef2";
export var partnerArrow__wrapperFourth = "whatToDo-module--partner-arrow__wrapper--fourth--31da4";
export var partnerArrow__wrapperSecond = "whatToDo-module--partner-arrow__wrapper--second--956ec";
export var partnerArrow__wrapperSeventh = "whatToDo-module--partner-arrow__wrapper--seventh--2fbce";
export var partnerArrow__wrapperSixth = "whatToDo-module--partner-arrow__wrapper--sixth--86c62";
export var partnerArrow__wrapperThird = "whatToDo-module--partner-arrow__wrapper--third--b13a1";
export var partnerPlatform = "whatToDo-module--partner-platform--184de";
export var secondAnimation = "whatToDo-module--second-animation--886db";
export var secondBlock = "whatToDo-module--second-block--1e39e";
export var secondBlock__company = "whatToDo-module--second-block__company--638e0";
export var secondBlock__companyBanks = "whatToDo-module--second-block__company--banks--10875";
export var secondBlock__companyDeveloper = "whatToDo-module--second-block__company--developer--1b0c8";
export var secondBlock__companyEvaluative = "whatToDo-module--second-block__company--evaluative--2c947";
export var secondBlock__companyInsurance = "whatToDo-module--second-block__company--insurance--4cbdb";
export var section = "whatToDo-module--section--7eba6";
export var textContainer = "whatToDo-module--text-container--83209";
export var thirdAnimation = "whatToDo-module--third-animation--787c3";
export var thirdBlock = "whatToDo-module--third-block--34888";
export var title = "whatToDo-module--title--6cc01";