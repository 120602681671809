import React from 'react'
import cn from 'classnames'
import Accordion from './Accordion'

import * as styles from './questions.module.less'

export default () => {
  return (
    <section
      id={'questions'}
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr'
      )}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Вопросы и&nbsp;ответы</h2>

        <div className={styles.body}>
          <Accordion />
        </div>
      </div>
    </section>
  )
}
