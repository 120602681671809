import React, { useEffect, useContext } from 'react'
import ModalContext from '../../context/modal'

const FixedBlock = ({ children }) => {
  const { visible } = useContext(ModalContext)

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.style.overflowY = 'auto'
    }
  }, [visible])

  return <>{children}</>
}

export default FixedBlock
