import React from 'react'
import LogoBlack from '../../images/logoBlack.png'

import * as styles from './schemaOrg.module.less'

const SchemaOrg = () => {
  return (
    <div className={styles.schemaOrg}>
      <div
        itemScope
        itemType="http://schema.org/LocalBusiness"
        className={styles.schemaOrg}
      >
        <span itemProp="name">Ипотека.Центр</span>
        Контакты:
        <div
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          Адрес:
          <span itemProp="streetAddress">Пресненская наб., 6с2</span>
          <span itemProp="postalCode">123317</span>
          <span itemProp="addressLocality">Москва</span>,
        </div>
        <time itemProp="openingHours" dateTime="Mo-Fr 09:00−20:00">
          С понедельника по пятницу, с 09.00 до 20.00
        </time>
        Телефон:<span itemProp="telephone">8 800 333-72-56</span>, Электронная
        почта: <span itemProp="email">feedback@ipoteka.center</span>
        <a itemProp="url" href="https://ipoteka.center/">
          https://ipoteka.center/
        </a>
        <img
          itemProp="image"
          src={LogoBlack}
          alt="Ипотека.Центр"
          loading="lazy"
          width={200}
          height={25}
        />
        <div property="geo" typeof="GeoCoordinates">
          Latitude: 59 deg 44 min 53.32 sec N Longitude: 37 deg 32 min 25.76 dec
          E
          <meta property="latitude" content="55.75" />
          <meta property="longitude" content="37.54" />
        </div>
      </div>

      <div
        itemScope
        itemType="http://schema.org/LocalBusiness"
        className={styles.schemaOrg}
      >
        <span itemProp="name">Ипотека.Центр</span>
        Контакты:
        <div
          itemProp="address"
          itemScope
          itemType="http://schema.org/PostalAddress"
        >
          Адрес:
          <span itemProp="streetAddress">Невский проспект, 153</span>
          <span itemProp="postalCode">191167</span>
          <span itemProp="addressLocality">Санкт-Петербург</span>,
        </div>
        <time itemProp="openingHours" dateTime="Mo-Fr 09:30−18:30">
          С понедельника по пятницу, с 09.30 до 18.30
        </time>
        Телефон:<span itemProp="telephone">8 800 333-72-56</span>, Электронная
        почта: <span itemProp="email">feedback@ipoteka.center</span>
        <a itemProp="url" href="https://ipoteka.center/">
          https://ipoteka.center/
        </a>
        <img
          itemProp="image"
          src={LogoBlack}
          alt="Ипотека.Центр"
          loading="lazy"
          width={200}
          height={25}
        />
        <div property="geo" typeof="GeoCoordinates">
          Latitude: 59 deg 55 min 31.13 sec N Longitude: 30 deg 22 min 48.32 dec
          E
          <meta property="latitude" content="59.92" />
          <meta property="longitude" content="30.38" />
        </div>
      </div>
    </div>
  )
}

export default SchemaOrg
