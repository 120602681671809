import React, { useContext } from 'react'
import cn from 'classnames'

import Mobile from './Sliders/Mobile'
import Tablet from './Sliders/Tablet'
import Desktop from './Sliders/Desktop'

import { dealStepsSource } from './entity'
import { useScreenSize } from '../../hooks'
import BonusContext from '../../context/bonuses'

import * as styles from './dealSteps.module.less'

export const DealSteps = () => {
  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isTablet = useScreenSize('tablet')
  const isBigTablet = useScreenSize('bigTablet')
  const isDesktop = useScreenSize('desktop')
  const isLargeDesktop = useScreenSize('largeDesktop')
  const bonuses = useContext(BonusContext)

  return (
    <section
      id="dealSteps"
      className={cn(
        styles.container,
        'container-padding--vr',
        'container-padding--hr'
      )}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          Весь цикл сделки с&nbsp;недвижимостью онлайн
        </h2>

        <div className={styles.stepsBody}>
          {(isMobile || isSmallTablet) && (
            <Mobile list={dealStepsSource(bonuses)} />
          )}
          {(isTablet || isBigTablet) && (
            <Tablet list={dealStepsSource(bonuses)} />
          )}
          {(isDesktop || isLargeDesktop) && (
            <Desktop list={dealStepsSource(bonuses)} />
          )}
        </div>
      </div>
    </section>
  )
}

export default DealSteps
